import { baseApi as api } from './baseApi';
export const addTagTypes = [
  'countries',
  'matches',
  'predictions',
  'rounds',
  'teams',
  'tournaments',
  'users',
  'areas',
  'rankings',
  'private/combinedroundsrankingdefinitions',
  'news',
  'companies',
  'files',
  'invitations',
  'translations',
  'dreamfinalsettings',
  'dreamfinalpredictions',
  'gamerules',
  'stats',
  'globalsettings',
  'tournamentcustomsettings',
  'joblogs',
  'trivias',
  'triviaSolutions',
  'triviaRankings',
  'domains',
  'internalrankings',
  'notifications',
  'awards',
  'auth',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      countriesControllerCreate: build.mutation<
        CountriesControllerCreateApiResponse,
        CountriesControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/countries`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['countries'],
      }),
      countriesControllerFindAll: build.query<
        CountriesControllerFindAllApiResponse,
        CountriesControllerFindAllApiArg
      >({
        query: () => ({ url: `/countries` }),
        providesTags: ['countries'],
      }),
      countriesControllerImportExternalCountries: build.mutation<
        CountriesControllerImportExternalCountriesApiResponse,
        CountriesControllerImportExternalCountriesApiArg
      >({
        query: () => ({ url: `/countries/external/import`, method: 'POST' }),
        invalidatesTags: ['countries'],
      }),
      countriesControllerFindOne: build.query<
        CountriesControllerFindOneApiResponse,
        CountriesControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/countries/${queryArg}` }),
        providesTags: ['countries'],
      }),
      countriesControllerUpdate: build.mutation<
        CountriesControllerUpdateApiResponse,
        CountriesControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/countries/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateCountryDto,
        }),
        invalidatesTags: ['countries'],
      }),
      countriesControllerRemove: build.mutation<
        CountriesControllerRemoveApiResponse,
        CountriesControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/countries/${queryArg}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['countries'],
      }),
      matchesControllerCreate: build.mutation<
        MatchesControllerCreateApiResponse,
        MatchesControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/matches`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['matches'],
      }),
      matchesControllerFindAll: build.query<
        MatchesControllerFindAllApiResponse,
        MatchesControllerFindAllApiArg
      >({
        query: () => ({ url: `/matches` }),
        providesTags: ['matches'],
      }),
      matchesControllerFindAllByCompany: build.query<
        MatchesControllerFindAllByCompanyApiResponse,
        MatchesControllerFindAllByCompanyApiArg
      >({
        query: (queryArg) => ({
          url: `/matches/me`,
          params: { date: queryArg },
        }),
        providesTags: ['matches'],
      }),
      matchesControllerFindRecentDatesFor: build.query<
        MatchesControllerFindRecentDatesForApiResponse,
        MatchesControllerFindRecentDatesForApiArg
      >({
        query: (queryArg) => ({
          url: `/matches/dates`,
          params: { timezone: queryArg },
        }),
        providesTags: ['matches'],
      }),
      matchesControllerFindOne: build.query<
        MatchesControllerFindOneApiResponse,
        MatchesControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/matches/${queryArg}` }),
        providesTags: ['matches'],
      }),
      matchesControllerUpdate: build.mutation<
        MatchesControllerUpdateApiResponse,
        MatchesControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/matches/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateMatchDto,
        }),
        invalidatesTags: ['matches'],
      }),
      matchesControllerRemove: build.mutation<
        MatchesControllerRemoveApiResponse,
        MatchesControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/matches/${queryArg}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['matches'],
      }),
      matchesControllerFindOneEvents: build.query<
        MatchesControllerFindOneEventsApiResponse,
        MatchesControllerFindOneEventsApiArg
      >({
        query: (queryArg) => ({
          url: `/matches/${queryArg.id}/events`,
          params: { type: queryArg['type'] },
        }),
        providesTags: ['matches'],
      }),
      matchesControllerFindLastResults: build.query<
        MatchesControllerFindLastResultsApiResponse,
        MatchesControllerFindLastResultsApiArg
      >({
        query: (queryArg) => ({
          url: `/matches/results/teams/${queryArg.teamId}/tournaments/${queryArg.tournamentId}`,
        }),
        providesTags: ['matches'],
      }),
      predictionsControllerAwardPoints: build.mutation<
        PredictionsControllerAwardPointsApiResponse,
        PredictionsControllerAwardPointsApiArg
      >({
        query: (queryArg) => ({
          url: `/predictions/points/${queryArg}`,
          method: 'POST',
        }),
        invalidatesTags: ['predictions'],
      }),
      predictionsControllerExplain: build.query<
        PredictionsControllerExplainApiResponse,
        PredictionsControllerExplainApiArg
      >({
        query: (queryArg) => ({ url: `/predictions/${queryArg}/explain` }),
        providesTags: ['predictions'],
      }),
      predictionsControllerFindAll: build.query<
        PredictionsControllerFindAllApiResponse,
        PredictionsControllerFindAllApiArg
      >({
        query: () => ({ url: `/predictions` }),
        providesTags: ['predictions'],
      }),
      predictionsControllerUpsert: build.mutation<
        PredictionsControllerUpsertApiResponse,
        PredictionsControllerUpsertApiArg
      >({
        query: (queryArg) => ({
          url: `/predictions`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['predictions'],
      }),
      predictionsControllerFindOne: build.query<
        PredictionsControllerFindOneApiResponse,
        PredictionsControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/predictions/${queryArg}` }),
        providesTags: ['predictions'],
      }),
      roundsControllerCreate: build.mutation<
        RoundsControllerCreateApiResponse,
        RoundsControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/rounds`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['rounds'],
      }),
      roundsControllerFindAll: build.query<
        RoundsControllerFindAllApiResponse,
        RoundsControllerFindAllApiArg
      >({
        query: () => ({ url: `/rounds` }),
        providesTags: ['rounds'],
      }),
      roundsControllerSummaryByDate: build.query<
        RoundsControllerSummaryByDateApiResponse,
        RoundsControllerSummaryByDateApiArg
      >({
        query: (queryArg) => ({
          url: `/rounds/summary`,
          params: { date: queryArg.date, timezone: queryArg.timezone },
        }),
        providesTags: ['rounds'],
      }),
      roundsControllerFindRecentRoundsFor: build.query<
        RoundsControllerFindRecentRoundsForApiResponse,
        RoundsControllerFindRecentRoundsForApiArg
      >({
        query: () => ({ url: `/rounds/recent` }),
        providesTags: ['rounds'],
      }),
      roundsControllerFindRecentRoundsInTournament: build.query<
        RoundsControllerFindRecentRoundsInTournamentApiResponse,
        RoundsControllerFindRecentRoundsInTournamentApiArg
      >({
        query: (queryArg) => ({ url: `/rounds/recent/${queryArg}` }),
        providesTags: ['rounds'],
      }),
      roundsControllerFindOne: build.query<
        RoundsControllerFindOneApiResponse,
        RoundsControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/rounds/${queryArg}` }),
        providesTags: ['rounds'],
      }),
      roundsControllerUpdate: build.mutation<
        RoundsControllerUpdateApiResponse,
        RoundsControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/rounds/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateRoundDto,
        }),
        invalidatesTags: ['rounds'],
      }),
      roundsControllerRemove: build.mutation<
        RoundsControllerRemoveApiResponse,
        RoundsControllerRemoveApiArg
      >({
        query: (queryArg) => ({ url: `/rounds/${queryArg}`, method: 'DELETE' }),
        invalidatesTags: ['rounds'],
      }),
      roundsControllerFindMatches: build.query<
        RoundsControllerFindMatchesApiResponse,
        RoundsControllerFindMatchesApiArg
      >({
        query: (queryArg) => ({ url: `/rounds/${queryArg}/matches` }),
        providesTags: ['rounds'],
      }),
      roundsControllerSummary: build.query<
        RoundsControllerSummaryApiResponse,
        RoundsControllerSummaryApiArg
      >({
        query: (queryArg) => ({ url: `/rounds/${queryArg}/summary` }),
        providesTags: ['rounds'],
      }),
      teamsControllerCreate: build.mutation<
        TeamsControllerCreateApiResponse,
        TeamsControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/teams`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['teams'],
      }),
      teamsControllerFindAll: build.query<
        TeamsControllerFindAllApiResponse,
        TeamsControllerFindAllApiArg
      >({
        query: () => ({ url: `/teams` }),
        providesTags: ['teams'],
      }),
      teamsControllerFindOne: build.query<
        TeamsControllerFindOneApiResponse,
        TeamsControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/teams/${queryArg}` }),
        providesTags: ['teams'],
      }),
      teamsControllerUpdate: build.mutation<
        TeamsControllerUpdateApiResponse,
        TeamsControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/teams/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateTeamDto,
        }),
        invalidatesTags: ['teams'],
      }),
      teamsControllerRemove: build.mutation<
        TeamsControllerRemoveApiResponse,
        TeamsControllerRemoveApiArg
      >({
        query: (queryArg) => ({ url: `/teams/${queryArg}`, method: 'DELETE' }),
        invalidatesTags: ['teams'],
      }),
      teamsControllerByTournament: build.query<
        TeamsControllerByTournamentApiResponse,
        TeamsControllerByTournamentApiArg
      >({
        query: (queryArg) => ({ url: `/teams/tournament/${queryArg}` }),
        providesTags: ['teams'],
      }),
      tournamentsControllerFindAll: build.query<
        TournamentsControllerFindAllApiResponse,
        TournamentsControllerFindAllApiArg
      >({
        query: () => ({ url: `/tournaments` }),
        providesTags: ['tournaments'],
      }),
      tournamentsControllerCreate: build.mutation<
        TournamentsControllerCreateApiResponse,
        TournamentsControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/tournaments`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['tournaments'],
      }),
      tournamentsControllerFindAllByCompany: build.query<
        TournamentsControllerFindAllByCompanyApiResponse,
        TournamentsControllerFindAllByCompanyApiArg
      >({
        query: () => ({ url: `/tournaments/me` }),
        providesTags: ['tournaments'],
      }),
      tournamentsControllerFindTournamentWinners: build.query<
        TournamentsControllerFindTournamentWinnersApiResponse,
        TournamentsControllerFindTournamentWinnersApiArg
      >({
        query: (queryArg) => ({ url: `/tournaments/${queryArg}/winners` }),
        providesTags: ['tournaments'],
      }),
      tournamentsControllerFindTournamentRounds: build.query<
        TournamentsControllerFindTournamentRoundsApiResponse,
        TournamentsControllerFindTournamentRoundsApiArg
      >({
        query: (queryArg) => ({ url: `/tournaments/${queryArg}/rounds` }),
        providesTags: ['tournaments'],
      }),
      tournamentsControllerGetSummary: build.query<
        TournamentsControllerGetSummaryApiResponse,
        TournamentsControllerGetSummaryApiArg
      >({
        query: () => ({ url: `/tournaments/summary` }),
        providesTags: ['tournaments'],
      }),
      tournamentsControllerFindOne: build.query<
        TournamentsControllerFindOneApiResponse,
        TournamentsControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/tournaments/${queryArg}` }),
        providesTags: ['tournaments'],
      }),
      tournamentsControllerUpdate: build.mutation<
        TournamentsControllerUpdateApiResponse,
        TournamentsControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/tournaments/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateTournamentDto,
        }),
        invalidatesTags: ['tournaments'],
      }),
      tournamentsControllerRemove: build.mutation<
        TournamentsControllerRemoveApiResponse,
        TournamentsControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/tournaments/${queryArg}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['tournaments'],
      }),
      tournamentImporterControllerReimport: build.mutation<
        TournamentImporterControllerReimportApiResponse,
        TournamentImporterControllerReimportApiArg
      >({
        query: (queryArg) => ({
          url: `/tournaments/${queryArg}/external/import`,
          method: 'POST',
        }),
        invalidatesTags: ['tournaments'],
      }),
      tournamentImporterControllerImportExternalLeagueSeason: build.mutation<
        TournamentImporterControllerImportExternalLeagueSeasonApiResponse,
        TournamentImporterControllerImportExternalLeagueSeasonApiArg
      >({
        query: (queryArg) => ({
          url: `/tournaments/external/import/${queryArg.externalLeagueId}`,
          method: 'POST',
          params: { season: queryArg.season },
        }),
        invalidatesTags: ['tournaments'],
      }),
      tournamentImporterControllerFindAll: build.query<
        TournamentImporterControllerFindAllApiResponse,
        TournamentImporterControllerFindAllApiArg
      >({
        query: (queryArg) => ({ url: `/tournaments/external/${queryArg}` }),
        providesTags: ['tournaments'],
      }),
      tournamentImporterControllerImportMatchResult: build.mutation<
        TournamentImporterControllerImportMatchResultApiResponse,
        TournamentImporterControllerImportMatchResultApiArg
      >({
        query: (queryArg) => ({
          url: `/tournaments/matches/${queryArg}/result`,
          method: 'POST',
        }),
        invalidatesTags: ['tournaments'],
      }),
      tournamentImporterControllerImportRoundResult: build.mutation<
        TournamentImporterControllerImportRoundResultApiResponse,
        TournamentImporterControllerImportRoundResultApiArg
      >({
        query: (queryArg) => ({
          url: `/tournaments/rounds/${queryArg}/result`,
          method: 'POST',
        }),
        invalidatesTags: ['tournaments'],
      }),
      tournamentImporterControllerImportMatchResultManual: build.mutation<
        TournamentImporterControllerImportMatchResultManualApiResponse,
        TournamentImporterControllerImportMatchResultManualApiArg
      >({
        query: (queryArg) => ({
          url: `/tournaments/matches/${queryArg.matchId}/result/manual`,
          method: 'POST',
          body: queryArg.matchResultBody,
        }),
        invalidatesTags: ['tournaments'],
      }),
      tournamentImporterControllerStandings: build.query<
        TournamentImporterControllerStandingsApiResponse,
        TournamentImporterControllerStandingsApiArg
      >({
        query: (queryArg) => ({
          url: `/tournaments/${queryArg.id}/standings/teams/${queryArg.teamId}`,
        }),
        providesTags: ['tournaments'],
      }),
      usersControllerGetProxyImage: build.query<
        UsersControllerGetProxyImageApiResponse,
        UsersControllerGetProxyImageApiArg
      >({
        query: (queryArg) => ({ url: `/users/proxy/images/${queryArg}` }),
        providesTags: ['users'],
      }),
      usersControllerCreate: build.mutation<
        UsersControllerCreateApiResponse,
        UsersControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/users`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['users'],
      }),
      usersControllerFindAll: build.query<
        UsersControllerFindAllApiResponse,
        UsersControllerFindAllApiArg
      >({
        query: () => ({ url: `/users` }),
        providesTags: ['users'],
      }),
      usersControllerFindAllResellerUsers: build.query<
        UsersControllerFindAllResellerUsersApiResponse,
        UsersControllerFindAllResellerUsersApiArg
      >({
        query: () => ({ url: `/users/resellers` }),
        providesTags: ['users'],
      }),
      usersControllerSearch: build.query<
        UsersControllerSearchApiResponse,
        UsersControllerSearchApiArg
      >({
        query: (queryArg) => ({
          url: `/users/search`,
          params: {
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
          },
        }),
        providesTags: ['users'],
      }),
      usersControllerFindMe: build.query<
        UsersControllerFindMeApiResponse,
        UsersControllerFindMeApiArg
      >({
        query: () => ({ url: `/users/me` }),
        providesTags: ['users'],
      }),
      usersControllerFindOne: build.query<
        UsersControllerFindOneApiResponse,
        UsersControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/users/${queryArg}` }),
        providesTags: ['users'],
      }),
      usersControllerUpdate: build.mutation<
        UsersControllerUpdateApiResponse,
        UsersControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateUserDto,
        }),
        invalidatesTags: ['users'],
      }),
      usersControllerRemove: build.mutation<
        UsersControllerRemoveApiResponse,
        UsersControllerRemoveApiArg
      >({
        query: (queryArg) => ({ url: `/users/${queryArg}`, method: 'DELETE' }),
        invalidatesTags: ['users'],
      }),
      usersControllerRemoveMulti: build.mutation<
        UsersControllerRemoveMultiApiResponse,
        UsersControllerRemoveMultiApiArg
      >({
        query: (queryArg) => ({
          url: `/users/multi`,
          method: 'DELETE',
          params: { ids: queryArg },
        }),
        invalidatesTags: ['users'],
      }),
      usersControllerDownloadCsv: build.mutation<
        UsersControllerDownloadCsvApiResponse,
        UsersControllerDownloadCsvApiArg
      >({
        query: () => ({ url: `/users/download/csv`, method: 'POST' }),
        invalidatesTags: ['users'],
      }),
      areasControllerFindAll: build.query<
        AreasControllerFindAllApiResponse,
        AreasControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/areas`,
          params: { areaLevel: queryArg },
        }),
        providesTags: ['areas'],
      }),
      areasControllerUpdate: build.mutation<
        AreasControllerUpdateApiResponse,
        AreasControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/areas/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateAreaDto,
        }),
        invalidatesTags: ['areas'],
      }),
      rankingsControllerCalculateRoundRanking: build.mutation<
        RankingsControllerCalculateRoundRankingApiResponse,
        RankingsControllerCalculateRoundRankingApiArg
      >({
        query: (queryArg) => ({
          url: `/rankings/rounds/${queryArg}`,
          method: 'POST',
        }),
        invalidatesTags: ['rankings'],
      }),
      rankingsControllerRoundRanking: build.query<
        RankingsControllerRoundRankingApiResponse,
        RankingsControllerRoundRankingApiArg
      >({
        query: (queryArg) => ({
          url: `/rankings/rounds/${queryArg.id}`,
          params: {
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
            areaId: queryArg.areaId,
            areaLevel: queryArg.areaLevel,
            internalRankingId: queryArg.internalRankingId,
          },
        }),
        providesTags: ['rankings'],
      }),
      rankingsControllerCalculateTournamentRanking: build.mutation<
        RankingsControllerCalculateTournamentRankingApiResponse,
        RankingsControllerCalculateTournamentRankingApiArg
      >({
        query: (queryArg) => ({
          url: `/rankings/tournaments/${queryArg}`,
          method: 'POST',
        }),
        invalidatesTags: ['rankings'],
      }),
      rankingsControllerTournamentRanking: build.query<
        RankingsControllerTournamentRankingApiResponse,
        RankingsControllerTournamentRankingApiArg
      >({
        query: (queryArg) => ({
          url: `/rankings/tournaments/${queryArg.id}`,
          params: {
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
            areaId: queryArg.areaId,
            areaLevel: queryArg.areaLevel,
            internalRankingId: queryArg.internalRankingId,
          },
        }),
        providesTags: ['rankings'],
      }),
      rankingsControllerForceCalculateDreamFinalTournamentRanking:
        build.mutation<
          RankingsControllerForceCalculateDreamFinalTournamentRankingApiResponse,
          RankingsControllerForceCalculateDreamFinalTournamentRankingApiArg
        >({
          query: (queryArg) => ({
            url: `/rankings/tournaments/${queryArg}/dreamfinal`,
            method: 'POST',
          }),
          invalidatesTags: ['rankings'],
        }),
      rankingsControllerTournamentRankingByArea: build.query<
        RankingsControllerTournamentRankingByAreaApiResponse,
        RankingsControllerTournamentRankingByAreaApiArg
      >({
        query: (queryArg) => ({
          url: `/rankings/tournaments/${queryArg.id}/areas`,
          params: {
            parentAreaId: queryArg.parentAreaId,
            areaLevel: queryArg.areaLevel,
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
          },
        }),
        providesTags: ['rankings'],
      }),
      rankingsControllerCalculateGlobalRanking: build.mutation<
        RankingsControllerCalculateGlobalRankingApiResponse,
        RankingsControllerCalculateGlobalRankingApiArg
      >({
        query: () => ({ url: `/rankings/global`, method: 'POST' }),
        invalidatesTags: ['rankings'],
      }),
      rankingsControllerGlobalRanking: build.query<
        RankingsControllerGlobalRankingApiResponse,
        RankingsControllerGlobalRankingApiArg
      >({
        query: (queryArg) => ({
          url: `/rankings/global`,
          params: {
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
            areaId: queryArg.areaId,
            areaLevel: queryArg.areaLevel,
            internalRankingId: queryArg.internalRankingId,
          },
        }),
        providesTags: ['rankings'],
      }),
      rankingsControllerRoundRankingRankingByArea: build.query<
        RankingsControllerRoundRankingRankingByAreaApiResponse,
        RankingsControllerRoundRankingRankingByAreaApiArg
      >({
        query: (queryArg) => ({
          url: `/rankings/rounds/${queryArg.id}/areas`,
          params: {
            parentAreaId: queryArg.parentAreaId,
            areaLevel: queryArg.areaLevel,
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
          },
        }),
        providesTags: ['rankings'],
      }),
      rankingsControllerRoundRankingMe: build.query<
        RankingsControllerRoundRankingMeApiResponse,
        RankingsControllerRoundRankingMeApiArg
      >({
        query: (queryArg) => ({ url: `/rankings/rounds/${queryArg}/me` }),
        providesTags: ['rankings'],
      }),
      rankingsControllerLatestRankingsMe: build.query<
        RankingsControllerLatestRankingsMeApiResponse,
        RankingsControllerLatestRankingsMeApiArg
      >({
        query: () => ({ url: `/rankings/me` }),
        providesTags: ['rankings'],
      }),
      rankingsControllerGlobalRankingByArea: build.query<
        RankingsControllerGlobalRankingByAreaApiResponse,
        RankingsControllerGlobalRankingByAreaApiArg
      >({
        query: (queryArg) => ({
          url: `/rankings/global/areas`,
          params: {
            parentAreaId: queryArg.parentAreaId,
            areaLevel: queryArg.areaLevel,
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
          },
        }),
        providesTags: ['rankings'],
      }),
      rankingsControllerCalculateCombinedRoundsRanking: build.mutation<
        RankingsControllerCalculateCombinedRoundsRankingApiResponse,
        RankingsControllerCalculateCombinedRoundsRankingApiArg
      >({
        query: (queryArg) => ({
          url: `/rankings/combinedrounds/${queryArg}`,
          method: 'POST',
        }),
        invalidatesTags: ['rankings'],
      }),
      rankingsControllerCombinedRoundsRanking: build.query<
        RankingsControllerCombinedRoundsRankingApiResponse,
        RankingsControllerCombinedRoundsRankingApiArg
      >({
        query: (queryArg) => ({
          url: `/rankings/combinedrounds/${queryArg.id}`,
          params: {
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
            areaId: queryArg.areaId,
            areaLevel: queryArg.areaLevel,
          },
        }),
        providesTags: ['rankings'],
      }),
      rankingsControllerCombinedRoundsRankingRankingByArea: build.query<
        RankingsControllerCombinedRoundsRankingRankingByAreaApiResponse,
        RankingsControllerCombinedRoundsRankingRankingByAreaApiArg
      >({
        query: (queryArg) => ({
          url: `/rankings/combinedrounds/${queryArg.id}/areas`,
          params: {
            parentAreaId: queryArg.parentAreaId,
            areaLevel: queryArg.areaLevel,
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
          },
        }),
        providesTags: ['rankings'],
      }),
      combinedRoundsRankingDefinitionsControllerCreate: build.mutation<
        CombinedRoundsRankingDefinitionsControllerCreateApiResponse,
        CombinedRoundsRankingDefinitionsControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/combinedroundsrankingdefinitions`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['private/combinedroundsrankingdefinitions'],
      }),
      combinedRoundsRankingDefinitionsControllerFindAll: build.query<
        CombinedRoundsRankingDefinitionsControllerFindAllApiResponse,
        CombinedRoundsRankingDefinitionsControllerFindAllApiArg
      >({
        query: () => ({ url: `/combinedroundsrankingdefinitions` }),
        providesTags: ['private/combinedroundsrankingdefinitions'],
      }),
      combinedRoundsRankingDefinitionsControllerFindOne: build.query<
        CombinedRoundsRankingDefinitionsControllerFindOneApiResponse,
        CombinedRoundsRankingDefinitionsControllerFindOneApiArg
      >({
        query: (queryArg) => ({
          url: `/combinedroundsrankingdefinitions/${queryArg}`,
        }),
        providesTags: ['private/combinedroundsrankingdefinitions'],
      }),
      combinedRoundsRankingDefinitionsControllerUpdate: build.mutation<
        CombinedRoundsRankingDefinitionsControllerUpdateApiResponse,
        CombinedRoundsRankingDefinitionsControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/combinedroundsrankingdefinitions/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateCombinedRoundsRankingDefinitionDto,
        }),
        invalidatesTags: ['private/combinedroundsrankingdefinitions'],
      }),
      combinedRoundsRankingDefinitionsControllerRemove: build.mutation<
        CombinedRoundsRankingDefinitionsControllerRemoveApiResponse,
        CombinedRoundsRankingDefinitionsControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/combinedroundsrankingdefinitions/${queryArg}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['private/combinedroundsrankingdefinitions'],
      }),
      newsControllerCreate: build.mutation<
        NewsControllerCreateApiResponse,
        NewsControllerCreateApiArg
      >({
        query: (queryArg) => ({ url: `/news`, method: 'POST', body: queryArg }),
        invalidatesTags: ['news'],
      }),
      newsControllerFindAll: build.query<
        NewsControllerFindAllApiResponse,
        NewsControllerFindAllApiArg
      >({
        query: () => ({ url: `/news` }),
        providesTags: ['news'],
      }),
      newsControllerCombinedNews: build.query<
        NewsControllerCombinedNewsApiResponse,
        NewsControllerCombinedNewsApiArg
      >({
        query: () => ({ url: `/news/all` }),
        providesTags: ['news'],
      }),
      newsControllerFetchParseHubNews: build.mutation<
        NewsControllerFetchParseHubNewsApiResponse,
        NewsControllerFetchParseHubNewsApiArg
      >({
        query: (queryArg) => ({
          url: `/news/parsehub/${queryArg}/run`,
          method: 'POST',
        }),
        invalidatesTags: ['news'],
      }),
      newsControllerFindOneRssByExternalid: build.query<
        NewsControllerFindOneRssByExternalidApiResponse,
        NewsControllerFindOneRssByExternalidApiArg
      >({
        query: (queryArg) => ({
          url: `/news/rss/${queryArg.externalId}`,
          params: { source: queryArg.source },
        }),
        providesTags: ['news'],
      }),
      newsControllerFindOne: build.query<
        NewsControllerFindOneApiResponse,
        NewsControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/news/${queryArg}` }),
        providesTags: ['news'],
      }),
      newsControllerUpdate: build.mutation<
        NewsControllerUpdateApiResponse,
        NewsControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/news/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateNewsDto,
        }),
        invalidatesTags: ['news'],
      }),
      newsControllerRemove: build.mutation<
        NewsControllerRemoveApiResponse,
        NewsControllerRemoveApiArg
      >({
        query: (queryArg) => ({ url: `/news/${queryArg}`, method: 'DELETE' }),
        invalidatesTags: ['news'],
      }),
      newsControllerDownloadRssFeeds: build.query<
        NewsControllerDownloadRssFeedsApiResponse,
        NewsControllerDownloadRssFeedsApiArg
      >({
        query: (queryArg) => ({
          url: `/news/rss/feeds/download`,
          params: { ids: queryArg },
        }),
        providesTags: ['news'],
      }),
      newsControllerAvailableRssFeeds: build.query<
        NewsControllerAvailableRssFeedsApiResponse,
        NewsControllerAvailableRssFeedsApiArg
      >({
        query: () => ({ url: `/news/rss/feeds/available` }),
        providesTags: ['news'],
      }),
      companiesControllerCreate: build.mutation<
        CompaniesControllerCreateApiResponse,
        CompaniesControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/companies`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['companies'],
      }),
      companiesControllerFindAll: build.query<
        CompaniesControllerFindAllApiResponse,
        CompaniesControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/companies`,
          params: {
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
          },
        }),
        providesTags: ['companies'],
      }),
      companiesControllerFindAvailableFonts: build.query<
        CompaniesControllerFindAvailableFontsApiResponse,
        CompaniesControllerFindAvailableFontsApiArg
      >({
        query: () => ({ url: `/companies/fonts` }),
        providesTags: ['companies'],
      }),
      companiesControllerFindAllResellers: build.query<
        CompaniesControllerFindAllResellersApiResponse,
        CompaniesControllerFindAllResellersApiArg
      >({
        query: (queryArg) => ({
          url: `/companies/resellers`,
          params: {
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
          },
        }),
        providesTags: ['companies'],
      }),
      companiesControllerFindMe: build.query<
        CompaniesControllerFindMeApiResponse,
        CompaniesControllerFindMeApiArg
      >({
        query: () => ({ url: `/companies/me` }),
        providesTags: ['companies'],
      }),
      companiesControllerUpdateMe: build.mutation<
        CompaniesControllerUpdateMeApiResponse,
        CompaniesControllerUpdateMeApiArg
      >({
        query: (queryArg) => ({
          url: `/companies/me`,
          method: 'PATCH',
          body: queryArg,
        }),
        invalidatesTags: ['companies'],
      }),
      companiesControllerFindMeWithAreas: build.query<
        CompaniesControllerFindMeWithAreasApiResponse,
        CompaniesControllerFindMeWithAreasApiArg
      >({
        query: () => ({ url: `/companies/me/withAreas` }),
        providesTags: ['companies'],
      }),
      companiesControllerSearch: build.query<
        CompaniesControllerSearchApiResponse,
        CompaniesControllerSearchApiArg
      >({
        query: (queryArg) => ({
          url: `/companies/search`,
          params: {
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
          },
        }),
        providesTags: ['companies'],
      }),
      companiesControllerManifest: build.query<
        CompaniesControllerManifestApiResponse,
        CompaniesControllerManifestApiArg
      >({
        query: (queryArg) => ({ url: `/companies/${queryArg}/manifest` }),
        providesTags: ['companies'],
      }),
      companiesControllerFindOne: build.query<
        CompaniesControllerFindOneApiResponse,
        CompaniesControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/companies/${queryArg}` }),
        providesTags: ['companies'],
      }),
      companiesControllerUpdate: build.mutation<
        CompaniesControllerUpdateApiResponse,
        CompaniesControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/companies/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateCompanyDto,
        }),
        invalidatesTags: ['companies'],
      }),
      companiesControllerRemove: build.mutation<
        CompaniesControllerRemoveApiResponse,
        CompaniesControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/companies/${queryArg}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['companies'],
      }),
      companiesControllerMyTournaments: build.query<
        CompaniesControllerMyTournamentsApiResponse,
        CompaniesControllerMyTournamentsApiArg
      >({
        query: () => ({ url: `/companies/me/tournaments` }),
        providesTags: ['companies'],
      }),
      companiesControllerTournaments: build.query<
        CompaniesControllerTournamentsApiResponse,
        CompaniesControllerTournamentsApiArg
      >({
        query: (queryArg) => ({ url: `/companies/${queryArg}/tournaments` }),
        providesTags: ['companies'],
      }),
      filesControllerUpload: build.mutation<
        FilesControllerUploadApiResponse,
        FilesControllerUploadApiArg
      >({
        query: () => ({ url: `/files/upload`, method: 'POST' }),
        invalidatesTags: ['files'],
      }),
      filesControllerUploadByUrl: build.mutation<
        FilesControllerUploadByUrlApiResponse,
        FilesControllerUploadByUrlApiArg
      >({
        query: () => ({ url: `/files/uploadByUrl`, method: 'POST' }),
        invalidatesTags: ['files'],
      }),
      invitationsControllerCreate: build.mutation<
        InvitationsControllerCreateApiResponse,
        InvitationsControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/invitations`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['invitations'],
      }),
      invitationsControllerFindAll: build.query<
        InvitationsControllerFindAllApiResponse,
        InvitationsControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/invitations`,
          params: {
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
          },
        }),
        providesTags: ['invitations'],
      }),
      invitationsControllerFindAllPendingManagers: build.query<
        InvitationsControllerFindAllPendingManagersApiResponse,
        InvitationsControllerFindAllPendingManagersApiArg
      >({
        query: (queryArg) => ({
          url: `/invitations/${queryArg}/pending/managers`,
        }),
        providesTags: ['invitations'],
      }),
      invitationsControllerImportFromExcel: build.mutation<
        InvitationsControllerImportFromExcelApiResponse,
        InvitationsControllerImportFromExcelApiArg
      >({
        query: (queryArg) => ({
          url: `/invitations/import/excel`,
          method: 'POST',
          body: queryArg.importFromExcelBody,
          params: { dryRun: queryArg.dryRun },
        }),
        invalidatesTags: ['invitations'],
      }),
      invitationsControllerDownloadSampleExcel: build.query<
        InvitationsControllerDownloadSampleExcelApiResponse,
        InvitationsControllerDownloadSampleExcelApiArg
      >({
        query: () => ({ url: `/invitations/import/excel` }),
        providesTags: ['invitations'],
      }),
      invitationsControllerFindOne: build.query<
        InvitationsControllerFindOneApiResponse,
        InvitationsControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/invitations/${queryArg}` }),
        providesTags: ['invitations'],
      }),
      invitationsControllerUpdate: build.mutation<
        InvitationsControllerUpdateApiResponse,
        InvitationsControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/invitations/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateInvitationDto,
        }),
        invalidatesTags: ['invitations'],
      }),
      invitationsControllerRemove: build.mutation<
        InvitationsControllerRemoveApiResponse,
        InvitationsControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/invitations/${queryArg}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['invitations'],
      }),
      invitationsControllerFindOneByJwt: build.query<
        InvitationsControllerFindOneByJwtApiResponse,
        InvitationsControllerFindOneByJwtApiArg
      >({
        query: (queryArg) => ({ url: `/invitations/jwt/${queryArg}` }),
        providesTags: ['invitations'],
      }),
      invitationsControllerFindOneByExternalId: build.query<
        InvitationsControllerFindOneByExternalIdApiResponse,
        InvitationsControllerFindOneByExternalIdApiArg
      >({
        query: (queryArg) => ({ url: `/invitations/externalId/${queryArg}` }),
        providesTags: ['invitations'],
      }),
      invitationsControllerRemoveAll: build.mutation<
        InvitationsControllerRemoveAllApiResponse,
        InvitationsControllerRemoveAllApiArg
      >({
        query: () => ({ url: `/invitations/all`, method: 'DELETE' }),
        invalidatesTags: ['invitations'],
      }),
      invitationsControllerRemoveMulti: build.mutation<
        InvitationsControllerRemoveMultiApiResponse,
        InvitationsControllerRemoveMultiApiArg
      >({
        query: (queryArg) => ({
          url: `/invitations/multi`,
          method: 'DELETE',
          params: { ids: queryArg },
        }),
        invalidatesTags: ['invitations'],
      }),
      invitationsControllerDownloadCsv: build.mutation<
        InvitationsControllerDownloadCsvApiResponse,
        InvitationsControllerDownloadCsvApiArg
      >({
        query: () => ({ url: `/invitations/download/csv`, method: 'POST' }),
        invalidatesTags: ['invitations'],
      }),
      translationsControllerCreate: build.mutation<
        TranslationsControllerCreateApiResponse,
        TranslationsControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/translations`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['translations'],
      }),
      translationsControllerCreateCommon: build.mutation<
        TranslationsControllerCreateCommonApiResponse,
        TranslationsControllerCreateCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/translations/common`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['translations'],
      }),
      translationsControllerAllCommonLocales: build.query<
        TranslationsControllerAllCommonLocalesApiResponse,
        TranslationsControllerAllCommonLocalesApiArg
      >({
        query: () => ({ url: `/translations/common` }),
        providesTags: ['translations'],
      }),
      translationsControllerAllCompanyLocales: build.query<
        TranslationsControllerAllCompanyLocalesApiResponse,
        TranslationsControllerAllCompanyLocalesApiArg
      >({
        query: () => ({ url: `/translations/company` }),
        providesTags: ['translations'],
      }),
      translationsControllerGetCompanyTranslations: build.query<
        TranslationsControllerGetCompanyTranslationsApiResponse,
        TranslationsControllerGetCompanyTranslationsApiArg
      >({
        query: (queryArg) => ({ url: `/translations/${queryArg}/company` }),
        providesTags: ['translations'],
      }),
      translationsControllerGetCommonTranslations: build.query<
        TranslationsControllerGetCommonTranslationsApiResponse,
        TranslationsControllerGetCommonTranslationsApiArg
      >({
        query: (queryArg) => ({ url: `/translations/${queryArg}/common` }),
        providesTags: ['translations'],
      }),
      translationsControllerRemove: build.mutation<
        TranslationsControllerRemoveApiResponse,
        TranslationsControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/translations/${queryArg}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['translations'],
      }),
      emailsControllerSend: build.mutation<
        EmailsControllerSendApiResponse,
        EmailsControllerSendApiArg
      >({
        query: (queryArg) => ({
          url: `/emails/send`,
          method: 'POST',
          body: queryArg,
        }),
      }),
      emailsControllerSendExternalSupport: build.mutation<
        EmailsControllerSendExternalSupportApiResponse,
        EmailsControllerSendExternalSupportApiArg
      >({
        query: (queryArg) => ({
          url: `/emails/send/external/support`,
          method: 'POST',
          body: queryArg,
        }),
      }),
      emailsControllerSendProfileSupport: build.mutation<
        EmailsControllerSendProfileSupportApiResponse,
        EmailsControllerSendProfileSupportApiArg
      >({
        query: (queryArg) => ({
          url: `/emails/send/profile/support`,
          method: 'POST',
          body: queryArg,
        }),
      }),
      dreamFinalSettingsControllerCreate: build.mutation<
        DreamFinalSettingsControllerCreateApiResponse,
        DreamFinalSettingsControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/dreamfinalsettings`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['dreamfinalsettings'],
      }),
      dreamFinalSettingsControllerMe: build.query<
        DreamFinalSettingsControllerMeApiResponse,
        DreamFinalSettingsControllerMeApiArg
      >({
        query: () => ({ url: `/dreamfinalsettings/tournaments/me` }),
        providesTags: ['dreamfinalsettings'],
      }),
      dreamFinalSettingsControllerSettingsByTournament: build.query<
        DreamFinalSettingsControllerSettingsByTournamentApiResponse,
        DreamFinalSettingsControllerSettingsByTournamentApiArg
      >({
        query: (queryArg) => ({
          url: `/dreamfinalsettings/tournaments/${queryArg}`,
        }),
        providesTags: ['dreamfinalsettings'],
      }),
      dreamFinalSettingsControllerFindOne: build.query<
        DreamFinalSettingsControllerFindOneApiResponse,
        DreamFinalSettingsControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/dreamfinalsettings/${queryArg}` }),
        providesTags: ['dreamfinalsettings'],
      }),
      dreamFinalSettingsControllerUpdate: build.mutation<
        DreamFinalSettingsControllerUpdateApiResponse,
        DreamFinalSettingsControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/dreamfinalsettings/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateDreamFinalSettingsDto,
        }),
        invalidatesTags: ['dreamfinalsettings'],
      }),
      dreamFinalSettingsControllerRemove: build.mutation<
        DreamFinalSettingsControllerRemoveApiResponse,
        DreamFinalSettingsControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/dreamfinalsettings/${queryArg}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['dreamfinalsettings'],
      }),
      dreamFinalPredictionControllerUpsert: build.mutation<
        DreamFinalPredictionControllerUpsertApiResponse,
        DreamFinalPredictionControllerUpsertApiArg
      >({
        query: (queryArg) => ({
          url: `/dreamfinalpredictions`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['dreamfinalpredictions'],
      }),
      dreamFinalPredictionControllerPredictionByTournament: build.query<
        DreamFinalPredictionControllerPredictionByTournamentApiResponse,
        DreamFinalPredictionControllerPredictionByTournamentApiArg
      >({
        query: (queryArg) => ({
          url: `/dreamfinalpredictions/tournaments/${queryArg}`,
        }),
        providesTags: ['dreamfinalpredictions'],
      }),
      dreamFinalPredictionControllerFindOne: build.query<
        DreamFinalPredictionControllerFindOneApiResponse,
        DreamFinalPredictionControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/dreamfinalpredictions/${queryArg}` }),
        providesTags: ['dreamfinalpredictions'],
      }),
      dreamFinalPredictionControllerRemove: build.mutation<
        DreamFinalPredictionControllerRemoveApiResponse,
        DreamFinalPredictionControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/dreamfinalpredictions/${queryArg}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['dreamfinalpredictions'],
      }),
      gameRulesControllerTermsAndConditions: build.query<
        GameRulesControllerTermsAndConditionsApiResponse,
        GameRulesControllerTermsAndConditionsApiArg
      >({
        query: () => ({ url: `/gamerules/termsandconditions` }),
        providesTags: ['gamerules'],
      }),
      gameRulesControllerUpsertTermsAndConditions: build.mutation<
        GameRulesControllerUpsertTermsAndConditionsApiResponse,
        GameRulesControllerUpsertTermsAndConditionsApiArg
      >({
        query: (queryArg) => ({
          url: `/gamerules/termsandconditions`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['gamerules'],
      }),
      gameRulesControllerTournamentRules: build.query<
        GameRulesControllerTournamentRulesApiResponse,
        GameRulesControllerTournamentRulesApiArg
      >({
        query: (queryArg) => ({ url: `/gamerules/tournaments/${queryArg}` }),
        providesTags: ['gamerules'],
      }),
      statsControllerStats: build.query<
        StatsControllerStatsApiResponse,
        StatsControllerStatsApiArg
      >({
        query: () => ({ url: `/stats` }),
        providesTags: ['stats'],
      }),
      globalSettingsControllerUpsert: build.mutation<
        GlobalSettingsControllerUpsertApiResponse,
        GlobalSettingsControllerUpsertApiArg
      >({
        query: (queryArg) => ({
          url: `/globalsettings`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['globalsettings'],
      }),
      globalSettingsControllerFindAll: build.query<
        GlobalSettingsControllerFindAllApiResponse,
        GlobalSettingsControllerFindAllApiArg
      >({
        query: () => ({ url: `/globalsettings` }),
        providesTags: ['globalsettings'],
      }),
      tournamentCustomSettingsControllerFindAllByCompany: build.query<
        TournamentCustomSettingsControllerFindAllByCompanyApiResponse,
        TournamentCustomSettingsControllerFindAllByCompanyApiArg
      >({
        query: (queryArg) => ({
          url: `/tournamentcustomsettings/companies/${queryArg}`,
        }),
        providesTags: ['tournamentcustomsettings'],
      }),
      tournamentCustomSettingsControllerFindAllByMe: build.query<
        TournamentCustomSettingsControllerFindAllByMeApiResponse,
        TournamentCustomSettingsControllerFindAllByMeApiArg
      >({
        query: () => ({ url: `/tournamentcustomsettings/me` }),
        providesTags: ['tournamentcustomsettings'],
      }),
      tournamentCustomSettingsControllerFindByTournament: build.query<
        TournamentCustomSettingsControllerFindByTournamentApiResponse,
        TournamentCustomSettingsControllerFindByTournamentApiArg
      >({
        query: (queryArg) => ({
          url: `/tournamentcustomsettings/tournaments/${queryArg}`,
        }),
        providesTags: ['tournamentcustomsettings'],
      }),
      tournamentCustomSettingsControllerCreate: build.mutation<
        TournamentCustomSettingsControllerCreateApiResponse,
        TournamentCustomSettingsControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/tournamentcustomsettings/tournaments/${queryArg.tournamentId}`,
          method: 'POST',
          body: queryArg.createTournamentCustomSettingsDto,
        }),
        invalidatesTags: ['tournamentcustomsettings'],
      }),
      tournamentCustomSettingsControllerRemove: build.mutation<
        TournamentCustomSettingsControllerRemoveApiResponse,
        TournamentCustomSettingsControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/tournamentcustomsettings/tournaments/${queryArg}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['tournamentcustomsettings'],
      }),
      jobLogsControllerFindAll: build.query<
        JobLogsControllerFindAllApiResponse,
        JobLogsControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/joblogs`,
          params: {
            skip: queryArg.skip,
            take: queryArg.take,
            type: queryArg['type'],
          },
        }),
        providesTags: ['joblogs'],
      }),
      jobLogsControllerFindOne: build.query<
        JobLogsControllerFindOneApiResponse,
        JobLogsControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/joblogs/${queryArg}` }),
        providesTags: ['joblogs'],
      }),
      triviasControllerCreateTemplate: build.mutation<
        TriviasControllerCreateTemplateApiResponse,
        TriviasControllerCreateTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/trivias/templates`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['trivias'],
      }),
      triviasControllerGetTriviaTemplates: build.query<
        TriviasControllerGetTriviaTemplatesApiResponse,
        TriviasControllerGetTriviaTemplatesApiArg
      >({
        query: () => ({ url: `/trivias/templates` }),
        providesTags: ['trivias'],
      }),
      triviasControllerCreate: build.mutation<
        TriviasControllerCreateApiResponse,
        TriviasControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/trivias/company`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['trivias'],
      }),
      triviasControllerGetCompanyTrivias: build.query<
        TriviasControllerGetCompanyTriviasApiResponse,
        TriviasControllerGetCompanyTriviasApiArg
      >({
        query: (queryArg) => ({
          url: `/trivias/company`,
          params: { status: queryArg },
        }),
        providesTags: ['trivias'],
      }),
      triviasControllerUpdate: build.mutation<
        TriviasControllerUpdateApiResponse,
        TriviasControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trivias/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateTriviaDto,
        }),
        invalidatesTags: ['trivias'],
      }),
      triviasControllerGetTrivia: build.query<
        TriviasControllerGetTriviaApiResponse,
        TriviasControllerGetTriviaApiArg
      >({
        query: (queryArg) => ({ url: `/trivias/${queryArg}` }),
        providesTags: ['trivias'],
      }),
      triviasControllerRemove: build.mutation<
        TriviasControllerRemoveApiResponse,
        TriviasControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/trivias/${queryArg}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['trivias'],
      }),
      triviaSolutionsControllerGetSolution: build.query<
        TriviaSolutionsControllerGetSolutionApiResponse,
        TriviaSolutionsControllerGetSolutionApiArg
      >({
        query: (queryArg) => ({ url: `/trivias/${queryArg}/solutions` }),
        providesTags: ['triviaSolutions'],
      }),
      triviaSolutionsControllerStartSolvingTrivia: build.mutation<
        TriviaSolutionsControllerStartSolvingTriviaApiResponse,
        TriviaSolutionsControllerStartSolvingTriviaApiArg
      >({
        query: (queryArg) => ({
          url: `/trivias/${queryArg.triviaId}/solutions`,
          method: 'POST',
          body: queryArg.createTriviaSolutionDto,
        }),
        invalidatesTags: ['triviaSolutions'],
      }),
      triviaSolutionsControllerResetTrivia: build.mutation<
        TriviaSolutionsControllerResetTriviaApiResponse,
        TriviaSolutionsControllerResetTriviaApiArg
      >({
        query: (queryArg) => ({
          url: `/trivias/${queryArg}/solutions/reset`,
          method: 'POST',
        }),
        invalidatesTags: ['triviaSolutions'],
      }),
      triviaSolutionsControllerFofeitTriviaSolution: build.mutation<
        TriviaSolutionsControllerFofeitTriviaSolutionApiResponse,
        TriviaSolutionsControllerFofeitTriviaSolutionApiArg
      >({
        query: (queryArg) => ({
          url: `/trivias/${queryArg.triviaId}/solutions/${queryArg.id}/forfeit`,
          method: 'POST',
        }),
        invalidatesTags: ['triviaSolutions'],
      }),
      triviaSolutionsControllerAnswerTriviaQuestion: build.mutation<
        TriviaSolutionsControllerAnswerTriviaQuestionApiResponse,
        TriviaSolutionsControllerAnswerTriviaQuestionApiArg
      >({
        query: (queryArg) => ({
          url: `/trivias/${queryArg.triviaId}/solutions/${queryArg.id}/answers`,
          method: 'POST',
          body: queryArg.createTriviaAnswerDto,
        }),
        invalidatesTags: ['triviaSolutions'],
      }),
      triviaSolutionsControllerDeleteTriviaSolution: build.mutation<
        TriviaSolutionsControllerDeleteTriviaSolutionApiResponse,
        TriviaSolutionsControllerDeleteTriviaSolutionApiArg
      >({
        query: (queryArg) => ({
          url: `/trivias/${queryArg.triviaId}/solutions/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['triviaSolutions'],
      }),
      triviaRankingsControllerCalculateSingleTriviaRanking: build.mutation<
        TriviaRankingsControllerCalculateSingleTriviaRankingApiResponse,
        TriviaRankingsControllerCalculateSingleTriviaRankingApiArg
      >({
        query: (queryArg) => ({
          url: `/triviaRankings/trivias/${queryArg}`,
          method: 'POST',
        }),
        invalidatesTags: ['triviaRankings'],
      }),
      triviaRankingsControllerSingleTriviaRanking: build.query<
        TriviaRankingsControllerSingleTriviaRankingApiResponse,
        TriviaRankingsControllerSingleTriviaRankingApiArg
      >({
        query: (queryArg) => ({
          url: `/triviaRankings/trivias/${queryArg.triviaId}`,
          params: {
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
            areaId: queryArg.areaId,
            areaLevel: queryArg.areaLevel,
          },
        }),
        providesTags: ['triviaRankings'],
      }),
      triviaRankingsControllerSingleTriviaRankingByArea: build.query<
        TriviaRankingsControllerSingleTriviaRankingByAreaApiResponse,
        TriviaRankingsControllerSingleTriviaRankingByAreaApiArg
      >({
        query: (queryArg) => ({
          url: `/triviaRankings/trivias/${queryArg.triviaId}/areas`,
          params: {
            parentAreaId: queryArg.parentAreaId,
            areaLevel: queryArg.areaLevel,
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
          },
        }),
        providesTags: ['triviaRankings'],
      }),
      triviaRankingsControllerCalculateGlobalTriviaRanking: build.mutation<
        TriviaRankingsControllerCalculateGlobalTriviaRankingApiResponse,
        TriviaRankingsControllerCalculateGlobalTriviaRankingApiArg
      >({
        query: () => ({ url: `/triviaRankings/global`, method: 'POST' }),
        invalidatesTags: ['triviaRankings'],
      }),
      triviaRankingsControllerGlobalTriviaRanking: build.query<
        TriviaRankingsControllerGlobalTriviaRankingApiResponse,
        TriviaRankingsControllerGlobalTriviaRankingApiArg
      >({
        query: (queryArg) => ({
          url: `/triviaRankings/global`,
          params: {
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
            areaId: queryArg.areaId,
            areaLevel: queryArg.areaLevel,
          },
        }),
        providesTags: ['triviaRankings'],
      }),
      triviaRankingsControllerGlobalTriviaRankingByArea: build.query<
        TriviaRankingsControllerGlobalTriviaRankingByAreaApiResponse,
        TriviaRankingsControllerGlobalTriviaRankingByAreaApiArg
      >({
        query: (queryArg) => ({
          url: `/triviaRankings/global/areas`,
          params: {
            parentAreaId: queryArg.parentAreaId,
            areaLevel: queryArg.areaLevel,
            skip: queryArg.skip,
            take: queryArg.take,
            search: queryArg.search,
          },
        }),
        providesTags: ['triviaRankings'],
      }),
      domainsControllerCreate: build.mutation<
        DomainsControllerCreateApiResponse,
        DomainsControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/domains`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['domains'],
      }),
      domainsControllerFindAll: build.query<
        DomainsControllerFindAllApiResponse,
        DomainsControllerFindAllApiArg
      >({
        query: () => ({ url: `/domains` }),
        providesTags: ['domains'],
      }),
      domainsControllerAcmCertificates: build.query<
        DomainsControllerAcmCertificatesApiResponse,
        DomainsControllerAcmCertificatesApiArg
      >({
        query: () => ({ url: `/domains/acm/all` }),
        providesTags: ['domains'],
      }),
      domainsControllerAcmCurrentCertificate: build.query<
        DomainsControllerAcmCurrentCertificateApiResponse,
        DomainsControllerAcmCurrentCertificateApiArg
      >({
        query: () => ({ url: `/domains/acm/current` }),
        providesTags: ['domains'],
      }),
      domainsControllerAcmCertificateChallenges: build.query<
        DomainsControllerAcmCertificateChallengesApiResponse,
        DomainsControllerAcmCertificateChallengesApiArg
      >({
        query: (queryArg) => ({
          url: `/domains/acm/challenge`,
          params: { certificateARN: queryArg },
        }),
        providesTags: ['domains'],
      }),
      domainsControllerAcmSolveCertificateChallenges: build.mutation<
        DomainsControllerAcmSolveCertificateChallengesApiResponse,
        DomainsControllerAcmSolveCertificateChallengesApiArg
      >({
        query: (queryArg) => ({
          url: `/domains/acm/challenge`,
          method: 'POST',
          params: { certificateARN: queryArg },
        }),
        invalidatesTags: ['domains'],
      }),
      domainsControllerSesIdentities: build.query<
        DomainsControllerSesIdentitiesApiResponse,
        DomainsControllerSesIdentitiesApiArg
      >({
        query: () => ({ url: `/domains/ses/all` }),
        providesTags: ['domains'],
      }),
      domainsControllerSesMissingIdentities: build.query<
        DomainsControllerSesMissingIdentitiesApiResponse,
        DomainsControllerSesMissingIdentitiesApiArg
      >({
        query: () => ({ url: `/domains/ses/missing` }),
        providesTags: ['domains'],
      }),
      domainsControllerCreateMissingSesIdentities: build.mutation<
        DomainsControllerCreateMissingSesIdentitiesApiResponse,
        DomainsControllerCreateMissingSesIdentitiesApiArg
      >({
        query: () => ({ url: `/domains/ses/missing`, method: 'POST' }),
        invalidatesTags: ['domains'],
      }),
      domainsControllerCloudfrontDistributions: build.query<
        DomainsControllerCloudfrontDistributionsApiResponse,
        DomainsControllerCloudfrontDistributionsApiArg
      >({
        query: () => ({ url: `/domains/cloudfront/distributions` }),
        providesTags: ['domains'],
      }),
      domainsControllerSyncCloudfrontDistributions: build.mutation<
        DomainsControllerSyncCloudfrontDistributionsApiResponse,
        DomainsControllerSyncCloudfrontDistributionsApiArg
      >({
        query: (queryArg) => ({
          url: `/domains/cloudfront/distributions`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['domains'],
      }),
      domainsControllerSyncCloudfrontDistributionById: build.mutation<
        DomainsControllerSyncCloudfrontDistributionByIdApiResponse,
        DomainsControllerSyncCloudfrontDistributionByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/domains/cloudfront/distributions/${queryArg.distributionId}`,
          method: 'POST',
          body: queryArg.certificateDetails,
        }),
        invalidatesTags: ['domains'],
      }),
      domainsControllerMissingDomains: build.query<
        DomainsControllerMissingDomainsApiResponse,
        DomainsControllerMissingDomainsApiArg
      >({
        query: () => ({ url: `/domains/missing` }),
        providesTags: ['domains'],
      }),
      domainsControllerConfigureMissingDomains: build.mutation<
        DomainsControllerConfigureMissingDomainsApiResponse,
        DomainsControllerConfigureMissingDomainsApiArg
      >({
        query: () => ({ url: `/domains/missing`, method: 'POST' }),
        invalidatesTags: ['domains'],
      }),
      domainsControllerFindOne: build.query<
        DomainsControllerFindOneApiResponse,
        DomainsControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/domains/${queryArg}` }),
        providesTags: ['domains'],
      }),
      domainsControllerUpdate: build.mutation<
        DomainsControllerUpdateApiResponse,
        DomainsControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/domains/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateDomainDto,
        }),
        invalidatesTags: ['domains'],
      }),
      domainsControllerRemove: build.mutation<
        DomainsControllerRemoveApiResponse,
        DomainsControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/domains/${queryArg}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['domains'],
      }),
      domainsControllerMigrateToCloudFlare: build.mutation<
        DomainsControllerMigrateToCloudFlareApiResponse,
        DomainsControllerMigrateToCloudFlareApiArg
      >({
        query: (queryArg) => ({
          url: `/domains/cloudFlare/migrate`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['domains'],
      }),
      domainsControllerCloudFlareStatus: build.query<
        DomainsControllerCloudFlareStatusApiResponse,
        DomainsControllerCloudFlareStatusApiArg
      >({
        query: () => ({ url: `/domains/cloudFlare/status` }),
        providesTags: ['domains'],
      }),
      internalRankingsControllerCreate: build.mutation<
        InternalRankingsControllerCreateApiResponse,
        InternalRankingsControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/internalrankings`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['internalrankings'],
      }),
      internalRankingsControllerSettingsByTournament: build.query<
        InternalRankingsControllerSettingsByTournamentApiResponse,
        InternalRankingsControllerSettingsByTournamentApiArg
      >({
        query: () => ({ url: `/internalrankings` }),
        providesTags: ['internalrankings'],
      }),
      internalRankingsControllerMe: build.query<
        InternalRankingsControllerMeApiResponse,
        InternalRankingsControllerMeApiArg
      >({
        query: () => ({ url: `/internalrankings/user` }),
        providesTags: ['internalrankings'],
      }),
      internalRankingsControllerFindOne: build.query<
        InternalRankingsControllerFindOneApiResponse,
        InternalRankingsControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/internalrankings/${queryArg}` }),
        providesTags: ['internalrankings'],
      }),
      internalRankingsControllerUpdate: build.mutation<
        InternalRankingsControllerUpdateApiResponse,
        InternalRankingsControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/internalrankings/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateInternalRankingDto,
        }),
        invalidatesTags: ['internalrankings'],
      }),
      internalRankingsControllerRemove: build.mutation<
        InternalRankingsControllerRemoveApiResponse,
        InternalRankingsControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/internalrankings/${queryArg}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['internalrankings'],
      }),
      internalRankingsControllerAcceptInternalRankingMember: build.mutation<
        InternalRankingsControllerAcceptInternalRankingMemberApiResponse,
        InternalRankingsControllerAcceptInternalRankingMemberApiArg
      >({
        query: (queryArg) => ({
          url: `/internalrankings/${queryArg}/accept`,
          method: 'POST',
        }),
        invalidatesTags: ['internalrankings'],
      }),
      internalRankingsControllerRejecInternalRankingMember: build.mutation<
        InternalRankingsControllerRejecInternalRankingMemberApiResponse,
        InternalRankingsControllerRejecInternalRankingMemberApiArg
      >({
        query: (queryArg) => ({
          url: `/internalrankings/${queryArg}/reject`,
          method: 'POST',
        }),
        invalidatesTags: ['internalrankings'],
      }),
      internalRankingsControllerRemoveInternalRankingMember: build.mutation<
        InternalRankingsControllerRemoveInternalRankingMemberApiResponse,
        InternalRankingsControllerRemoveInternalRankingMemberApiArg
      >({
        query: (queryArg) => ({
          url: `/internalrankings/${queryArg.id}/members/${queryArg.memberId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['internalrankings'],
      }),
      internalRankingsControllerAddInternalRankingMember: build.mutation<
        InternalRankingsControllerAddInternalRankingMemberApiResponse,
        InternalRankingsControllerAddInternalRankingMemberApiArg
      >({
        query: (queryArg) => ({
          url: `/internalrankings/${queryArg.id}/members`,
          method: 'POST',
          body: queryArg.entityDto,
        }),
        invalidatesTags: ['internalrankings'],
      }),
      notificationsControllerFindAll: build.query<
        NotificationsControllerFindAllApiResponse,
        NotificationsControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/notifications/user`,
          params: {
            skip: queryArg.skip,
            take: queryArg.take,
            kind: queryArg.kind,
            status: queryArg.status,
            fromDate: queryArg.fromDate,
          },
        }),
        providesTags: ['notifications'],
      }),
      notificationsControllerFindOne: build.mutation<
        NotificationsControllerFindOneApiResponse,
        NotificationsControllerFindOneApiArg
      >({
        query: (queryArg) => ({
          url: `/notifications/${queryArg}/read`,
          method: 'POST',
        }),
        invalidatesTags: ['notifications'],
      }),
      awardsControllerUpsert: build.mutation<
        AwardsControllerUpsertApiResponse,
        AwardsControllerUpsertApiArg
      >({
        query: (queryArg) => ({
          url: `/awards`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['awards'],
      }),
      awardsControllerFindAll: build.query<
        AwardsControllerFindAllApiResponse,
        AwardsControllerFindAllApiArg
      >({
        query: () => ({ url: `/awards` }),
        providesTags: ['awards'],
      }),
      awardsControllerRemove: build.mutation<
        AwardsControllerRemoveApiResponse,
        AwardsControllerRemoveApiArg
      >({
        query: (queryArg) => ({ url: `/awards/${queryArg}`, method: 'DELETE' }),
        invalidatesTags: ['awards'],
      }),
      awardsControllerFindOne: build.query<
        AwardsControllerFindOneApiResponse,
        AwardsControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/awards/${queryArg}` }),
        providesTags: ['awards'],
      }),
      authControllerRegister: build.mutation<
        AuthControllerRegisterApiResponse,
        AuthControllerRegisterApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/register`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['auth'],
      }),
      authControllerLogin: build.mutation<
        AuthControllerLoginApiResponse,
        AuthControllerLoginApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/login`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['auth'],
      }),
      authControllerGoogleLogin: build.mutation<
        AuthControllerGoogleLoginApiResponse,
        AuthControllerGoogleLoginApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/google/login`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['auth'],
      }),
      authControllerVerifyEmail: build.mutation<
        AuthControllerVerifyEmailApiResponse,
        AuthControllerVerifyEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/verify/${queryArg}`,
          method: 'POST',
        }),
        invalidatesTags: ['auth'],
      }),
      authControllerAcceptInvitation: build.mutation<
        AuthControllerAcceptInvitationApiResponse,
        AuthControllerAcceptInvitationApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/invitations/${queryArg.invitationId}/accept`,
          method: 'POST',
          body: queryArg.createUserDto,
        }),
        invalidatesTags: ['auth'],
      }),
      authControllerAcceptInvitationByJwt: build.mutation<
        AuthControllerAcceptInvitationByJwtApiResponse,
        AuthControllerAcceptInvitationByJwtApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/invitations/${queryArg.jwt}/acceptByJwt`,
          method: 'POST',
          body: queryArg.createUserDto,
        }),
        invalidatesTags: ['auth'],
      }),
      authControllerSendVerificationEmail: build.mutation<
        AuthControllerSendVerificationEmailApiResponse,
        AuthControllerSendVerificationEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/email/${queryArg}/resend`,
          method: 'POST',
        }),
        invalidatesTags: ['auth'],
      }),
      authControllerInitRecoverPassword: build.mutation<
        AuthControllerInitRecoverPasswordApiResponse,
        AuthControllerInitRecoverPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/init-recover`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['auth'],
      }),
      authControllerFinishRecoverPassword: build.mutation<
        AuthControllerFinishRecoverPasswordApiResponse,
        AuthControllerFinishRecoverPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/finish-recover`,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: ['auth'],
      }),
    }),
    overrideExisting: true,
  });
export { injectedRtkApi as futbolProdeApi };
export type CountriesControllerCreateApiResponse = /** status 201  */ Country;
export type CountriesControllerCreateApiArg = CreateCountryDto;
export type CountriesControllerFindAllApiResponse =
  /** status 200  */ CountriesList;
export type CountriesControllerFindAllApiArg = void;
export type CountriesControllerImportExternalCountriesApiResponse =
  /** status 201  */ Country[];
export type CountriesControllerImportExternalCountriesApiArg = void;
export type CountriesControllerFindOneApiResponse = /** status 200  */ Country;
export type CountriesControllerFindOneApiArg = string;
export type CountriesControllerUpdateApiResponse = unknown;
export type CountriesControllerUpdateApiArg = {
  id: string;
  updateCountryDto: UpdateCountryDto;
};
export type CountriesControllerRemoveApiResponse = unknown;
export type CountriesControllerRemoveApiArg = string;
export type MatchesControllerCreateApiResponse = unknown;
export type MatchesControllerCreateApiArg = CreateMatchDto;
export type MatchesControllerFindAllApiResponse = unknown;
export type MatchesControllerFindAllApiArg = void;
export type MatchesControllerFindAllByCompanyApiResponse =
  /** status 200  */ Match[];
export type MatchesControllerFindAllByCompanyApiArg = string;
export type MatchesControllerFindRecentDatesForApiResponse =
  /** status 200  */ MatchDatesList;
export type MatchesControllerFindRecentDatesForApiArg = string;
export type MatchesControllerFindOneApiResponse = /** status 200  */ Match;
export type MatchesControllerFindOneApiArg = string;
export type MatchesControllerUpdateApiResponse = unknown;
export type MatchesControllerUpdateApiArg = {
  id: string;
  updateMatchDto: UpdateMatchDto;
};
export type MatchesControllerRemoveApiResponse = unknown;
export type MatchesControllerRemoveApiArg = string;
export type MatchesControllerFindOneEventsApiResponse =
  /** status 200  */ MatchEvent[];
export type MatchesControllerFindOneEventsApiArg = {
  id: string;
  type: string;
};
export type MatchesControllerFindLastResultsApiResponse =
  /** status 200  */ MatchResult[];
export type MatchesControllerFindLastResultsApiArg = {
  teamId: string;
  tournamentId: string;
};
export type PredictionsControllerAwardPointsApiResponse = unknown;
export type PredictionsControllerAwardPointsApiArg = string;
export type PredictionsControllerExplainApiResponse =
  /** status 200  */ Explanation;
export type PredictionsControllerExplainApiArg = string;
export type PredictionsControllerFindAllApiResponse = unknown;
export type PredictionsControllerFindAllApiArg = void;
export type PredictionsControllerUpsertApiResponse = unknown;
export type PredictionsControllerUpsertApiArg = CreatePredictionDto;
export type PredictionsControllerFindOneApiResponse = unknown;
export type PredictionsControllerFindOneApiArg = number;
export type RoundsControllerCreateApiResponse = unknown;
export type RoundsControllerCreateApiArg = CreateRoundDto;
export type RoundsControllerFindAllApiResponse = unknown;
export type RoundsControllerFindAllApiArg = void;
export type RoundsControllerSummaryByDateApiResponse =
  /** status 200  */ Match[];
export type RoundsControllerSummaryByDateApiArg = {
  date: string;
  timezone: string;
};
export type RoundsControllerFindRecentRoundsForApiResponse =
  /** status 200  */ OrderedRoundsList;
export type RoundsControllerFindRecentRoundsForApiArg = void;
export type RoundsControllerFindRecentRoundsInTournamentApiResponse =
  /** status 200  */ OrderedRoundsList;
export type RoundsControllerFindRecentRoundsInTournamentApiArg = string;
export type RoundsControllerFindOneApiResponse = /** status 200  */ Round;
export type RoundsControllerFindOneApiArg = string;
export type RoundsControllerUpdateApiResponse = unknown;
export type RoundsControllerUpdateApiArg = {
  id: string;
  updateRoundDto: UpdateRoundDto;
};
export type RoundsControllerRemoveApiResponse = unknown;
export type RoundsControllerRemoveApiArg = string;
export type RoundsControllerFindMatchesApiResponse = /** status 200  */ Match[];
export type RoundsControllerFindMatchesApiArg = string;
export type RoundsControllerSummaryApiResponse = /** status 200  */ Match[];
export type RoundsControllerSummaryApiArg = string;
export type TeamsControllerCreateApiResponse = /** status 201  */ Team;
export type TeamsControllerCreateApiArg = CreateTeamDto;
export type TeamsControllerFindAllApiResponse = /** status 200  */ TeamsList;
export type TeamsControllerFindAllApiArg = void;
export type TeamsControllerFindOneApiResponse = /** status 200  */ Team;
export type TeamsControllerFindOneApiArg = string;
export type TeamsControllerUpdateApiResponse = unknown;
export type TeamsControllerUpdateApiArg = {
  id: string;
  updateTeamDto: UpdateTeamDto;
};
export type TeamsControllerRemoveApiResponse = unknown;
export type TeamsControllerRemoveApiArg = string;
export type TeamsControllerByTournamentApiResponse = /** status 200  */ Team[];
export type TeamsControllerByTournamentApiArg = string;
export type TournamentsControllerFindAllApiResponse =
  /** status 200  */ TournamentsList;
export type TournamentsControllerFindAllApiArg = void;
export type TournamentsControllerCreateApiResponse =
  /** status 201  */ Tournament;
export type TournamentsControllerCreateApiArg = CreateTournamentDto;
export type TournamentsControllerFindAllByCompanyApiResponse =
  /** status 200  */ Tournament[];
export type TournamentsControllerFindAllByCompanyApiArg = void;
export type TournamentsControllerFindTournamentWinnersApiResponse =
  /** status 200  */ Team[];
export type TournamentsControllerFindTournamentWinnersApiArg = string;
export type TournamentsControllerFindTournamentRoundsApiResponse =
  /** status 200  */ {
    pagination?: {
      skip?: number;
      take?: number;
      count?: number;
      page?: number;
    };
    data?: Round[];
  };
export type TournamentsControllerFindTournamentRoundsApiArg = string;
export type TournamentsControllerGetSummaryApiResponse =
  /** status 200  */ TournamentsList;
export type TournamentsControllerGetSummaryApiArg = void;
export type TournamentsControllerFindOneApiResponse =
  /** status 200  */ TournamentDetails;
export type TournamentsControllerFindOneApiArg = string;
export type TournamentsControllerUpdateApiResponse = unknown;
export type TournamentsControllerUpdateApiArg = {
  id: string;
  updateTournamentDto: UpdateTournamentDto;
};
export type TournamentsControllerRemoveApiResponse = unknown;
export type TournamentsControllerRemoveApiArg = string;
export type TournamentImporterControllerReimportApiResponse =
  /** status 200  */ Tournament;
export type TournamentImporterControllerReimportApiArg = string;
export type TournamentImporterControllerImportExternalLeagueSeasonApiResponse =
  /** status 201  */ Tournament;
export type TournamentImporterControllerImportExternalLeagueSeasonApiArg = {
  externalLeagueId: string;
  season: string;
};
export type TournamentImporterControllerFindAllApiResponse =
  /** status 200  */ ImportableTournament[];
export type TournamentImporterControllerFindAllApiArg = string;
export type TournamentImporterControllerImportMatchResultApiResponse = unknown;
export type TournamentImporterControllerImportMatchResultApiArg = string;
export type TournamentImporterControllerImportRoundResultApiResponse = unknown;
export type TournamentImporterControllerImportRoundResultApiArg = string;
export type TournamentImporterControllerImportMatchResultManualApiResponse =
  unknown;
export type TournamentImporterControllerImportMatchResultManualApiArg = {
  matchId: string;
  matchResultBody: MatchResultBody;
};
export type TournamentImporterControllerStandingsApiResponse =
  /** status 200  */ TeamStanding;
export type TournamentImporterControllerStandingsApiArg = {
  id: string;
  teamId: string;
};
export type UsersControllerGetProxyImageApiResponse = unknown;
export type UsersControllerGetProxyImageApiArg = string;
export type UsersControllerCreateApiResponse = /** status 201  */ User;
export type UsersControllerCreateApiArg = CreateUserDto;
export type UsersControllerFindAllApiResponse = /** status 200  */ User[];
export type UsersControllerFindAllApiArg = void;
export type UsersControllerFindAllResellerUsersApiResponse =
  /** status 200  */ User[];
export type UsersControllerFindAllResellerUsersApiArg = void;
export type UsersControllerSearchApiResponse = /** status 200  */ {
  pagination?: {
    skip?: number;
    take?: number;
    count?: number;
    page?: number;
  };
  data?: User[];
};
export type UsersControllerSearchApiArg = {
  skip?: number;
  take?: number;
  search?: string;
};
export type UsersControllerFindMeApiResponse = /** status 200  */ User;
export type UsersControllerFindMeApiArg = void;
export type UsersControllerFindOneApiResponse = /** status 200  */ User;
export type UsersControllerFindOneApiArg = string;
export type UsersControllerUpdateApiResponse = /** status 200  */ User;
export type UsersControllerUpdateApiArg = {
  id: number;
  updateUserDto: UpdateUserDto;
};
export type UsersControllerRemoveApiResponse = unknown;
export type UsersControllerRemoveApiArg = string;
export type UsersControllerRemoveMultiApiResponse = unknown;
export type UsersControllerRemoveMultiApiArg = string;
export type UsersControllerDownloadCsvApiResponse = unknown;
export type UsersControllerDownloadCsvApiArg = void;
export type AreasControllerFindAllApiResponse = /** status 200  */ Area[];
export type AreasControllerFindAllApiArg = number;
export type AreasControllerUpdateApiResponse = unknown;
export type AreasControllerUpdateApiArg = {
  id: number;
  updateAreaDto: UpdateAreaDto;
};
export type RankingsControllerCalculateRoundRankingApiResponse = unknown;
export type RankingsControllerCalculateRoundRankingApiArg = number;
export type RankingsControllerRoundRankingApiResponse = /** status 200  */ {
  pagination?: {
    skip?: number;
    take?: number;
    count?: number;
    page?: number;
  };
  data?: Ranking[];
};
export type RankingsControllerRoundRankingApiArg = {
  skip?: number;
  take?: number;
  search?: string;
  areaId?: number;
  areaLevel?: number;
  internalRankingId?: number;
  id: number;
};
export type RankingsControllerCalculateTournamentRankingApiResponse = unknown;
export type RankingsControllerCalculateTournamentRankingApiArg = number;
export type RankingsControllerTournamentRankingApiResponse =
  /** status 200  */ {
    pagination?: {
      skip?: number;
      take?: number;
      count?: number;
      page?: number;
    };
    data?: Ranking[];
  };
export type RankingsControllerTournamentRankingApiArg = {
  skip?: number;
  take?: number;
  search?: string;
  areaId?: number;
  areaLevel?: number;
  internalRankingId?: number;
  id: number;
};
export type RankingsControllerForceCalculateDreamFinalTournamentRankingApiResponse =
  unknown;
export type RankingsControllerForceCalculateDreamFinalTournamentRankingApiArg =
  number;
export type RankingsControllerTournamentRankingByAreaApiResponse =
  /** status 200  */ {
    pagination?: {
      skip?: number;
      take?: number;
      count?: number;
      page?: number;
    };
    data?: AreaRanking[];
  };
export type RankingsControllerTournamentRankingByAreaApiArg = {
  parentAreaId?: number;
  areaLevel?: number;
  skip?: number;
  take?: number;
  search?: string;
  id: number;
};
export type RankingsControllerCalculateGlobalRankingApiResponse = unknown;
export type RankingsControllerCalculateGlobalRankingApiArg = void;
export type RankingsControllerGlobalRankingApiResponse = /** status 200  */ {
  pagination?: {
    skip?: number;
    take?: number;
    count?: number;
    page?: number;
  };
  data?: Ranking[];
};
export type RankingsControllerGlobalRankingApiArg = {
  skip?: number;
  take?: number;
  search?: string;
  areaId?: number;
  areaLevel?: number;
  internalRankingId?: number;
};
export type RankingsControllerRoundRankingRankingByAreaApiResponse =
  /** status 200  */ {
    pagination?: {
      skip?: number;
      take?: number;
      count?: number;
      page?: number;
    };
    data?: AreaRanking[];
  };
export type RankingsControllerRoundRankingRankingByAreaApiArg = {
  parentAreaId?: number;
  areaLevel?: number;
  skip?: number;
  take?: number;
  search?: string;
  id: number;
};
export type RankingsControllerRoundRankingMeApiResponse =
  /** status 200  */ Ranking;
export type RankingsControllerRoundRankingMeApiArg = number;
export type RankingsControllerLatestRankingsMeApiResponse =
  /** status 200  */ LatestRankingsDto;
export type RankingsControllerLatestRankingsMeApiArg = void;
export type RankingsControllerGlobalRankingByAreaApiResponse =
  /** status 200  */ {
    pagination?: {
      skip?: number;
      take?: number;
      count?: number;
      page?: number;
    };
    data?: AreaRanking[];
  };
export type RankingsControllerGlobalRankingByAreaApiArg = {
  parentAreaId?: number;
  areaLevel?: number;
  skip?: number;
  take?: number;
  search?: string;
};
export type RankingsControllerCalculateCombinedRoundsRankingApiResponse =
  unknown;
export type RankingsControllerCalculateCombinedRoundsRankingApiArg = number;
export type RankingsControllerCombinedRoundsRankingApiResponse =
  /** status 200  */ {
    pagination?: {
      skip?: number;
      take?: number;
      count?: number;
      page?: number;
    };
    data?: Ranking[];
  };
export type RankingsControllerCombinedRoundsRankingApiArg = {
  skip?: number;
  take?: number;
  search?: string;
  areaId?: number;
  areaLevel?: number;
  id: number;
};
export type RankingsControllerCombinedRoundsRankingRankingByAreaApiResponse =
  /** status 200  */ {
    pagination?: {
      skip?: number;
      take?: number;
      count?: number;
      page?: number;
    };
    data?: AreaRanking[];
  };
export type RankingsControllerCombinedRoundsRankingRankingByAreaApiArg = {
  parentAreaId?: number;
  areaLevel?: number;
  skip?: number;
  take?: number;
  search?: string;
  id: number;
};
export type CombinedRoundsRankingDefinitionsControllerCreateApiResponse =
  /** status 201  */ CombinedRoundsRankingDefinition;
export type CombinedRoundsRankingDefinitionsControllerCreateApiArg =
  CreateCombinedRoundsRankingDefinitionDto;
export type CombinedRoundsRankingDefinitionsControllerFindAllApiResponse =
  /** status 200  */ CombinedRoundsRankingDefinition[];
export type CombinedRoundsRankingDefinitionsControllerFindAllApiArg = void;
export type CombinedRoundsRankingDefinitionsControllerFindOneApiResponse =
  /** status 200  */ CombinedRoundsRankingDefinition;
export type CombinedRoundsRankingDefinitionsControllerFindOneApiArg = string;
export type CombinedRoundsRankingDefinitionsControllerUpdateApiResponse =
  /** status 200  */ CombinedRoundsRankingDefinition;
export type CombinedRoundsRankingDefinitionsControllerUpdateApiArg = {
  id: string;
  updateCombinedRoundsRankingDefinitionDto: UpdateCombinedRoundsRankingDefinitionDto;
};
export type CombinedRoundsRankingDefinitionsControllerRemoveApiResponse =
  unknown;
export type CombinedRoundsRankingDefinitionsControllerRemoveApiArg = string;
export type NewsControllerCreateApiResponse = /** status 201  */ News;
export type NewsControllerCreateApiArg = CreateNewsDto;
export type NewsControllerFindAllApiResponse = /** status 200  */ News[];
export type NewsControllerFindAllApiArg = void;
export type NewsControllerCombinedNewsApiResponse =
  /** status 200  */ NewsDetails[];
export type NewsControllerCombinedNewsApiArg = void;
export type NewsControllerFetchParseHubNewsApiResponse = unknown;
export type NewsControllerFetchParseHubNewsApiArg = string;
export type NewsControllerFindOneRssByExternalidApiResponse =
  /** status 200  */ NewsDetails;
export type NewsControllerFindOneRssByExternalidApiArg = {
  externalId: string;
  source: string;
};
export type NewsControllerFindOneApiResponse = /** status 200  */ News;
export type NewsControllerFindOneApiArg = string;
export type NewsControllerUpdateApiResponse = unknown;
export type NewsControllerUpdateApiArg = {
  id: string;
  updateNewsDto: UpdateNewsDto;
};
export type NewsControllerRemoveApiResponse = unknown;
export type NewsControllerRemoveApiArg = string;
export type NewsControllerDownloadRssFeedsApiResponse =
  /** status 200  */ NewsSourceDownload[];
export type NewsControllerDownloadRssFeedsApiArg = string;
export type NewsControllerAvailableRssFeedsApiResponse =
  /** status 200  */ NewsSource[];
export type NewsControllerAvailableRssFeedsApiArg = void;
export type CompaniesControllerCreateApiResponse = /** status 201  */ Company;
export type CompaniesControllerCreateApiArg = CreateCompanyDto;
export type CompaniesControllerFindAllApiResponse = /** status 200  */ {
  pagination?: {
    skip?: number;
    take?: number;
    count?: number;
    page?: number;
  };
  data?: CompanyWithUsersCount[];
};
export type CompaniesControllerFindAllApiArg = {
  skip?: number;
  take?: number;
  search?: string;
};
export type CompaniesControllerFindAvailableFontsApiResponse =
  /** status 200  */ string[];
export type CompaniesControllerFindAvailableFontsApiArg = void;
export type CompaniesControllerFindAllResellersApiResponse =
  /** status 200  */ {
    pagination?: {
      skip?: number;
      take?: number;
      count?: number;
      page?: number;
    };
    data?: Company[];
  };
export type CompaniesControllerFindAllResellersApiArg = {
  skip?: number;
  take?: number;
  search?: string;
};
export type CompaniesControllerFindMeApiResponse = /** status 200  */ Company;
export type CompaniesControllerFindMeApiArg = void;
export type CompaniesControllerUpdateMeApiResponse = /** status 200  */ Company;
export type CompaniesControllerUpdateMeApiArg = UpdateCompanyDto;
export type CompaniesControllerFindMeWithAreasApiResponse =
  /** status 200  */ Company;
export type CompaniesControllerFindMeWithAreasApiArg = void;
export type CompaniesControllerSearchApiResponse = /** status 200  */ Company[];
export type CompaniesControllerSearchApiArg = {
  skip?: number;
  take?: number;
  search?: string;
};
export type CompaniesControllerManifestApiResponse = unknown;
export type CompaniesControllerManifestApiArg = number;
export type CompaniesControllerFindOneApiResponse = /** status 200  */ Company;
export type CompaniesControllerFindOneApiArg = string;
export type CompaniesControllerUpdateApiResponse = unknown;
export type CompaniesControllerUpdateApiArg = {
  id: string;
  updateCompanyDto: UpdateCompanyDto;
};
export type CompaniesControllerRemoveApiResponse = unknown;
export type CompaniesControllerRemoveApiArg = string;
export type CompaniesControllerMyTournamentsApiResponse =
  /** status 200  */ CompanyWithUsersCount;
export type CompaniesControllerMyTournamentsApiArg = void;
export type CompaniesControllerTournamentsApiResponse =
  /** status 200  */ Company;
export type CompaniesControllerTournamentsApiArg = string;
export type FilesControllerUploadApiResponse =
  /** status 200  */ FileUploadResponse;
export type FilesControllerUploadApiArg = void;
export type FilesControllerUploadByUrlApiResponse =
  /** status 200  */ FileUploadResponse;
export type FilesControllerUploadByUrlApiArg = void;
export type InvitationsControllerCreateApiResponse =
  /** status 201  */ Invitation;
export type InvitationsControllerCreateApiArg = CreateInvitationDto;
export type InvitationsControllerFindAllApiResponse = /** status 200  */ {
  pagination?: {
    skip?: number;
    take?: number;
    count?: number;
    page?: number;
  };
  data?: Invitation[];
};
export type InvitationsControllerFindAllApiArg = {
  skip?: number;
  take?: number;
  search?: string;
};
export type InvitationsControllerFindAllPendingManagersApiResponse =
  /** status 200  */ Invitation[];
export type InvitationsControllerFindAllPendingManagersApiArg = string;
export type InvitationsControllerImportFromExcelApiResponse =
  /** status 200  */ ImportReport;
export type InvitationsControllerImportFromExcelApiArg = {
  dryRun: string;
  importFromExcelBody: ImportFromExcelBody;
};
export type InvitationsControllerDownloadSampleExcelApiResponse = unknown;
export type InvitationsControllerDownloadSampleExcelApiArg = void;
export type InvitationsControllerFindOneApiResponse =
  /** status 200  */ Invitation;
export type InvitationsControllerFindOneApiArg = string;
export type InvitationsControllerUpdateApiResponse =
  /** status 200  */ Invitation;
export type InvitationsControllerUpdateApiArg = {
  id: string;
  updateInvitationDto: UpdateInvitationDto;
};
export type InvitationsControllerRemoveApiResponse = unknown;
export type InvitationsControllerRemoveApiArg = string;
export type InvitationsControllerFindOneByJwtApiResponse =
  /** status 200  */ Invitation;
export type InvitationsControllerFindOneByJwtApiArg = string;
export type InvitationsControllerFindOneByExternalIdApiResponse =
  /** status 200  */ Invitation;
export type InvitationsControllerFindOneByExternalIdApiArg = string;
export type InvitationsControllerRemoveAllApiResponse =
  /** status 200  */ DeleteManyResult;
export type InvitationsControllerRemoveAllApiArg = void;
export type InvitationsControllerRemoveMultiApiResponse =
  /** status 200  */ DeleteManyResult;
export type InvitationsControllerRemoveMultiApiArg = string;
export type InvitationsControllerDownloadCsvApiResponse = unknown;
export type InvitationsControllerDownloadCsvApiArg = void;
export type TranslationsControllerCreateApiResponse = unknown;
export type TranslationsControllerCreateApiArg = CreateTranslationDto;
export type TranslationsControllerCreateCommonApiResponse = unknown;
export type TranslationsControllerCreateCommonApiArg = CreateTranslationDto;
export type TranslationsControllerAllCommonLocalesApiResponse =
  /** status 200  */ Translation[];
export type TranslationsControllerAllCommonLocalesApiArg = void;
export type TranslationsControllerAllCompanyLocalesApiResponse =
  /** status 200  */ Translation[];
export type TranslationsControllerAllCompanyLocalesApiArg = void;
export type TranslationsControllerGetCompanyTranslationsApiResponse = unknown;
export type TranslationsControllerGetCompanyTranslationsApiArg = string;
export type TranslationsControllerGetCommonTranslationsApiResponse = unknown;
export type TranslationsControllerGetCommonTranslationsApiArg = string;
export type TranslationsControllerRemoveApiResponse = unknown;
export type TranslationsControllerRemoveApiArg = string;
export type EmailsControllerSendApiResponse = unknown;
export type EmailsControllerSendApiArg = SendEmailDto;
export type EmailsControllerSendExternalSupportApiResponse = unknown;
export type EmailsControllerSendExternalSupportApiArg = ExternalSupportEmailDto;
export type EmailsControllerSendProfileSupportApiResponse = unknown;
export type EmailsControllerSendProfileSupportApiArg = ProfileSupportEmailDto;
export type DreamFinalSettingsControllerCreateApiResponse =
  /** status 201  */ DreamFinalSettings;
export type DreamFinalSettingsControllerCreateApiArg =
  CreateDreamFinalSettingsDto;
export type DreamFinalSettingsControllerMeApiResponse =
  /** status 200  */ TournamentAndDreamFinal[];
export type DreamFinalSettingsControllerMeApiArg = void;
export type DreamFinalSettingsControllerSettingsByTournamentApiResponse =
  /** status 200  */ DreamFinalSettings;
export type DreamFinalSettingsControllerSettingsByTournamentApiArg = string;
export type DreamFinalSettingsControllerFindOneApiResponse =
  /** status 200  */ DreamFinalSettings;
export type DreamFinalSettingsControllerFindOneApiArg = string;
export type DreamFinalSettingsControllerUpdateApiResponse = unknown;
export type DreamFinalSettingsControllerUpdateApiArg = {
  id: string;
  updateDreamFinalSettingsDto: UpdateDreamFinalSettingsDto;
};
export type DreamFinalSettingsControllerRemoveApiResponse = unknown;
export type DreamFinalSettingsControllerRemoveApiArg = string;
export type DreamFinalPredictionControllerUpsertApiResponse =
  /** status 201  */ DreamFinalPrediction;
export type DreamFinalPredictionControllerUpsertApiArg =
  CreateDreamFinalPredictionDto;
export type DreamFinalPredictionControllerPredictionByTournamentApiResponse =
  /** status 200  */ DreamFinalPrediction;
export type DreamFinalPredictionControllerPredictionByTournamentApiArg = string;
export type DreamFinalPredictionControllerFindOneApiResponse =
  /** status 200  */ DreamFinalPrediction;
export type DreamFinalPredictionControllerFindOneApiArg = string;
export type DreamFinalPredictionControllerRemoveApiResponse = unknown;
export type DreamFinalPredictionControllerRemoveApiArg = string;
export type GameRulesControllerTermsAndConditionsApiResponse =
  /** status 200  */ TermsAndConditions;
export type GameRulesControllerTermsAndConditionsApiArg = void;
export type GameRulesControllerUpsertTermsAndConditionsApiResponse =
  /** status 200  */ TermsAndConditions;
export type GameRulesControllerUpsertTermsAndConditionsApiArg =
  CreateTermsAndConditionsDto;
export type GameRulesControllerTournamentRulesApiResponse =
  /** status 200  */ GameRules;
export type GameRulesControllerTournamentRulesApiArg = string;
export type StatsControllerStatsApiResponse = /** status 200  */ Stats;
export type StatsControllerStatsApiArg = void;
export type GlobalSettingsControllerUpsertApiResponse =
  /** status 201  */ GlobalSettings;
export type GlobalSettingsControllerUpsertApiArg = CreateGlobalSettingsDto;
export type GlobalSettingsControllerFindAllApiResponse =
  /** status 200  */ GlobalSettings;
export type GlobalSettingsControllerFindAllApiArg = void;
export type TournamentCustomSettingsControllerFindAllByCompanyApiResponse =
  /** status 200  */ TournamentCustomSettings[];
export type TournamentCustomSettingsControllerFindAllByCompanyApiArg = string;
export type TournamentCustomSettingsControllerFindAllByMeApiResponse =
  /** status 200  */ TournamentCustomSettings[];
export type TournamentCustomSettingsControllerFindAllByMeApiArg = void;
export type TournamentCustomSettingsControllerFindByTournamentApiResponse =
  /** status 200  */ TournamentCustomSettings;
export type TournamentCustomSettingsControllerFindByTournamentApiArg = string;
export type TournamentCustomSettingsControllerCreateApiResponse =
  /** status 201  */ TournamentCustomSettings;
export type TournamentCustomSettingsControllerCreateApiArg = {
  tournamentId: string;
  createTournamentCustomSettingsDto: CreateTournamentCustomSettingsDto;
};
export type TournamentCustomSettingsControllerRemoveApiResponse = unknown;
export type TournamentCustomSettingsControllerRemoveApiArg = string;
export type JobLogsControllerFindAllApiResponse = /** status 200  */ {
  pagination?: {
    skip?: number;
    take?: number;
    count?: number;
    page?: number;
  };
  data?: JobLog[];
};
export type JobLogsControllerFindAllApiArg = {
  skip?: number;
  take?: number;
  type?:
    | 'ranking:generate'
    | 'tournament:import'
    | 'match:import-result'
    | 'domains:godaddy-create-domain-forward'
    | 'domains:godaddy-create-site-subdomains'
    | 'domains:acm-request-certificate'
    | 'domains:acm-solve-challenge'
    | 'domains:ses-create-identity'
    | 'domains:ses-verify-identity'
    | 'domains:ses-solve-challenge'
    | 'domains:cloudfront-update-distribution'
    | 'news:parsehub:fetch'
    | 'invitation:import';
};
export type JobLogsControllerFindOneApiResponse = /** status 200  */ JobLog;
export type JobLogsControllerFindOneApiArg = string;
export type TriviasControllerCreateTemplateApiResponse =
  /** status 201  */ Trivia;
export type TriviasControllerCreateTemplateApiArg = CreateTriviaDto;
export type TriviasControllerGetTriviaTemplatesApiResponse =
  /** status 200  */ Trivia[];
export type TriviasControllerGetTriviaTemplatesApiArg = void;
export type TriviasControllerCreateApiResponse = /** status 201  */ Trivia;
export type TriviasControllerCreateApiArg = CreateTriviaDto;
export type TriviasControllerGetCompanyTriviasApiResponse =
  /** status 200  */ Trivia[];
export type TriviasControllerGetCompanyTriviasApiArg =
  | 'draft'
  | 'published'
  | 'paused';
export type TriviasControllerUpdateApiResponse = unknown;
export type TriviasControllerUpdateApiArg = {
  id: number;
  updateTriviaDto: UpdateTriviaDto;
};
export type TriviasControllerGetTriviaApiResponse = /** status 200  */ Trivia;
export type TriviasControllerGetTriviaApiArg = number;
export type TriviasControllerRemoveApiResponse = unknown;
export type TriviasControllerRemoveApiArg = number;
export type TriviaSolutionsControllerGetSolutionApiResponse =
  /** status 200  */ PlayableTrivia;
export type TriviaSolutionsControllerGetSolutionApiArg = number;
export type TriviaSolutionsControllerStartSolvingTriviaApiResponse =
  /** status 201  */ TriviaSolution;
export type TriviaSolutionsControllerStartSolvingTriviaApiArg = {
  triviaId: number;
  createTriviaSolutionDto: CreateTriviaSolutionDto;
};
export type TriviaSolutionsControllerResetTriviaApiResponse = unknown;
export type TriviaSolutionsControllerResetTriviaApiArg = number;
export type TriviaSolutionsControllerFofeitTriviaSolutionApiResponse = unknown;
export type TriviaSolutionsControllerFofeitTriviaSolutionApiArg = {
  id: number;
  triviaId: number;
};
export type TriviaSolutionsControllerAnswerTriviaQuestionApiResponse =
  /** status 201  */ TriviaAnswer;
export type TriviaSolutionsControllerAnswerTriviaQuestionApiArg = {
  id: number;
  triviaId: number;
  createTriviaAnswerDto: CreateTriviaAnswerDto;
};
export type TriviaSolutionsControllerDeleteTriviaSolutionApiResponse =
  /** status 201  */ TriviaAnswer;
export type TriviaSolutionsControllerDeleteTriviaSolutionApiArg = {
  id: number;
  triviaId: number;
};
export type TriviaRankingsControllerCalculateSingleTriviaRankingApiResponse =
  unknown;
export type TriviaRankingsControllerCalculateSingleTriviaRankingApiArg = string;
export type TriviaRankingsControllerSingleTriviaRankingApiResponse =
  /** status 200  */ {
    pagination?: {
      skip?: number;
      take?: number;
      count?: number;
      page?: number;
    };
    data?: TriviaRanking[];
  };
export type TriviaRankingsControllerSingleTriviaRankingApiArg = {
  triviaId: string;
  skip?: number;
  take?: number;
  search?: string;
  areaId?: number;
  areaLevel?: number;
};
export type TriviaRankingsControllerSingleTriviaRankingByAreaApiResponse =
  /** status 200  */ {
    pagination?: {
      skip?: number;
      take?: number;
      count?: number;
      page?: number;
    };
    data?: AreaTriviaRanking[];
  };
export type TriviaRankingsControllerSingleTriviaRankingByAreaApiArg = {
  triviaId: string;
  parentAreaId?: number;
  areaLevel?: number;
  skip?: number;
  take?: number;
  search?: string;
};
export type TriviaRankingsControllerCalculateGlobalTriviaRankingApiResponse =
  unknown;
export type TriviaRankingsControllerCalculateGlobalTriviaRankingApiArg = void;
export type TriviaRankingsControllerGlobalTriviaRankingApiResponse =
  /** status 200  */ {
    pagination?: {
      skip?: number;
      take?: number;
      count?: number;
      page?: number;
    };
    data?: TriviaRanking[];
  };
export type TriviaRankingsControllerGlobalTriviaRankingApiArg = {
  skip?: number;
  take?: number;
  search?: string;
  areaId?: number;
  areaLevel?: number;
};
export type TriviaRankingsControllerGlobalTriviaRankingByAreaApiResponse =
  /** status 200  */ {
    pagination?: {
      skip?: number;
      take?: number;
      count?: number;
      page?: number;
    };
    data?: AreaTriviaRanking[];
  };
export type TriviaRankingsControllerGlobalTriviaRankingByAreaApiArg = {
  parentAreaId?: number;
  areaLevel?: number;
  skip?: number;
  take?: number;
  search?: string;
};
export type DomainsControllerCreateApiResponse = /** status 201  */ Domain;
export type DomainsControllerCreateApiArg = string[];
export type DomainsControllerFindAllApiResponse =
  /** status 200  */ DomainsList;
export type DomainsControllerFindAllApiArg = void;
export type DomainsControllerAcmCertificatesApiResponse = unknown;
export type DomainsControllerAcmCertificatesApiArg = void;
export type DomainsControllerAcmCurrentCertificateApiResponse = unknown;
export type DomainsControllerAcmCurrentCertificateApiArg = void;
export type DomainsControllerAcmCertificateChallengesApiResponse = unknown;
export type DomainsControllerAcmCertificateChallengesApiArg = string;
export type DomainsControllerAcmSolveCertificateChallengesApiResponse = unknown;
export type DomainsControllerAcmSolveCertificateChallengesApiArg = string;
export type DomainsControllerSesIdentitiesApiResponse = unknown;
export type DomainsControllerSesIdentitiesApiArg = void;
export type DomainsControllerSesMissingIdentitiesApiResponse = unknown;
export type DomainsControllerSesMissingIdentitiesApiArg = void;
export type DomainsControllerCreateMissingSesIdentitiesApiResponse = unknown;
export type DomainsControllerCreateMissingSesIdentitiesApiArg = void;
export type DomainsControllerCloudfrontDistributionsApiResponse = unknown;
export type DomainsControllerCloudfrontDistributionsApiArg = void;
export type DomainsControllerSyncCloudfrontDistributionsApiResponse = unknown;
export type DomainsControllerSyncCloudfrontDistributionsApiArg =
  CertificateDetails;
export type DomainsControllerSyncCloudfrontDistributionByIdApiResponse =
  unknown;
export type DomainsControllerSyncCloudfrontDistributionByIdApiArg = {
  distributionId: string;
  certificateDetails: CertificateDetails;
};
export type DomainsControllerMissingDomainsApiResponse = unknown;
export type DomainsControllerMissingDomainsApiArg = void;
export type DomainsControllerConfigureMissingDomainsApiResponse = unknown;
export type DomainsControllerConfigureMissingDomainsApiArg = void;
export type DomainsControllerFindOneApiResponse = /** status 200  */ Domain;
export type DomainsControllerFindOneApiArg = string;
export type DomainsControllerUpdateApiResponse = unknown;
export type DomainsControllerUpdateApiArg = {
  id: string;
  updateDomainDto: UpdateDomainDto;
};
export type DomainsControllerRemoveApiResponse = unknown;
export type DomainsControllerRemoveApiArg = string;
export type DomainsControllerMigrateToCloudFlareApiResponse =
  /** status 201  */ Domain;
export type DomainsControllerMigrateToCloudFlareApiArg = CreateDomainDto;
export type DomainsControllerCloudFlareStatusApiResponse =
  /** status 201  */ Domain;
export type DomainsControllerCloudFlareStatusApiArg = void;
export type InternalRankingsControllerCreateApiResponse =
  /** status 201  */ InternalRanking;
export type InternalRankingsControllerCreateApiArg = CreateInternalRankingDto;
export type InternalRankingsControllerSettingsByTournamentApiResponse =
  /** status 200  */ InternalRanking[];
export type InternalRankingsControllerSettingsByTournamentApiArg = void;
export type InternalRankingsControllerMeApiResponse =
  /** status 200  */ InternalRanking[];
export type InternalRankingsControllerMeApiArg = void;
export type InternalRankingsControllerFindOneApiResponse =
  /** status 200  */ InternalRanking;
export type InternalRankingsControllerFindOneApiArg = string;
export type InternalRankingsControllerUpdateApiResponse = unknown;
export type InternalRankingsControllerUpdateApiArg = {
  id: string;
  updateInternalRankingDto: UpdateInternalRankingDto;
};
export type InternalRankingsControllerRemoveApiResponse = unknown;
export type InternalRankingsControllerRemoveApiArg = string;
export type InternalRankingsControllerAcceptInternalRankingMemberApiResponse =
  unknown;
export type InternalRankingsControllerAcceptInternalRankingMemberApiArg =
  string;
export type InternalRankingsControllerRejecInternalRankingMemberApiResponse =
  unknown;
export type InternalRankingsControllerRejecInternalRankingMemberApiArg = string;
export type InternalRankingsControllerRemoveInternalRankingMemberApiResponse =
  unknown;
export type InternalRankingsControllerRemoveInternalRankingMemberApiArg = {
  id: string;
  memberId: string;
};
export type InternalRankingsControllerAddInternalRankingMemberApiResponse =
  /** status 201  */ InternalRanking;
export type InternalRankingsControllerAddInternalRankingMemberApiArg = {
  id: string;
  entityDto: EntityDto;
};
export type NotificationsControllerFindAllApiResponse = /** status 200  */ {
  pagination?: {
    skip?: number;
    take?: number;
    count?: number;
    page?: number;
  };
  data?: Notification[];
};
export type NotificationsControllerFindAllApiArg = {
  skip?: number;
  take?: number;
  kind?:
    | 'internal-ranking:invitation-sent'
    | 'internal-ranking:invitation-accepted';
  status?: 'read' | 'unread';
  fromDate?: string;
};
export type NotificationsControllerFindOneApiResponse =
  /** status 200  */ Notification;
export type NotificationsControllerFindOneApiArg = string;
export type AwardsControllerUpsertApiResponse = unknown;
export type AwardsControllerUpsertApiArg = CreateAwardDto[];
export type AwardsControllerFindAllApiResponse = /** status 200  */ Award[];
export type AwardsControllerFindAllApiArg = void;
export type AwardsControllerRemoveApiResponse = unknown;
export type AwardsControllerRemoveApiArg = number;
export type AwardsControllerFindOneApiResponse = unknown;
export type AwardsControllerFindOneApiArg = number;
export type AuthControllerRegisterApiResponse =
  /** status 201  */ UserWithToken;
export type AuthControllerRegisterApiArg = CreateUserDto;
export type AuthControllerLoginApiResponse = /** status 200  */ UserWithToken;
export type AuthControllerLoginApiArg = LoginUserDto;
export type AuthControllerGoogleLoginApiResponse =
  /** status 200  */ UserWithToken;
export type AuthControllerGoogleLoginApiArg = GoogleSignInDto;
export type AuthControllerVerifyEmailApiResponse = unknown;
export type AuthControllerVerifyEmailApiArg = string;
export type AuthControllerAcceptInvitationApiResponse = unknown;
export type AuthControllerAcceptInvitationApiArg = {
  invitationId: string;
  createUserDto: CreateUserDto;
};
export type AuthControllerAcceptInvitationByJwtApiResponse = unknown;
export type AuthControllerAcceptInvitationByJwtApiArg = {
  jwt: string;
  createUserDto: CreateUserDto;
};
export type AuthControllerSendVerificationEmailApiResponse = unknown;
export type AuthControllerSendVerificationEmailApiArg = string;
export type AuthControllerInitRecoverPasswordApiResponse = unknown;
export type AuthControllerInitRecoverPasswordApiArg = UpdateUserDto;
export type AuthControllerFinishRecoverPasswordApiResponse = unknown;
export type AuthControllerFinishRecoverPasswordApiArg = UpdateUserDto;
export type Country = {
  id: number;
  name: string;
  alpha2Code?: string;
  flagUrl?: string;
  teams: string[];
  tournaments: string[];
};
export type CreateCountryDto = {
  name: string;
  flagUrl: string;
  alpha2Code: string;
};
export type CountriesList = {
  count: number;
  data: Country[];
};
export type UpdateCountryDto = {
  name?: string;
  flagUrl?: string;
  alpha2Code?: string;
};
export type EntityDto = {
  id: number;
};
export type CreateMatchDto = {
  date: string;
  venue: string;
  referee: string;
  homeTeam: EntityDto;
  awayTeam: EntityDto;
  hasPenalties: boolean;
  isTwoWaysSecondMatch: boolean;
  wasSuspended: boolean;
  alreadySentOpenNotification: boolean;
  homeTeamGoals?: number;
  awayTeamGoals?: number;
  status?:
    | 'FT'
    | 'AET'
    | 'PEN'
    | 'SUSP'
    | 'INT'
    | 'PST'
    | 'CANC'
    | 'ABD'
    | 'AWD'
    | 'WO'
    | '1H'
    | '2H'
    | 'ET'
    | 'HT'
    | 'BT'
    | 'P'
    | 'LIVE'
    | 'TBD'
    | 'NS';
};
export type Team = {
  id: number;
  name: string;
  logoUrl?: string;
  country: Country;
  externalId?: string;
};
export type Tournament = {
  id: number;
  name: string;
  logoUrl?: string;
  isActive?: boolean;
  endDate?: string;
  hasPenalties?: boolean;
  hasTwoWayMatches: boolean;
  importNewMatchesWithPenalties: boolean;
  showNoMoreRoundsWarning: boolean;
  country: Country;
  rounds: Round[];
  season?: string;
  externalId?: string;
  defaultRssFeeds?: string[];
};
export type Round = {
  id: number;
  name: string;
  date: string;
  hasPenalties: boolean;
  position: number;
  matches: Match[];
  tournament: Tournament;
  externalId?: string;
};
export type TriviaQuestionChoice = {
  id: number;
  option: string;
  isCorrect: boolean;
  triviaQuestion: TriviaQuestion;
};
export type Area = {
  id: number;
  name: string;
  company: Company;
  parentArea: Area;
  childrenAreas: Area;
};
export type DreamFinalSettings = {
  id: number;
  tournament: Tournament;
  awardedPositions: number;
  awardedPoints: number;
  limitRound: Round;
  isEnabled: boolean;
  company: Company;
};
export type Banner = {
  desktopImageUrl?: string;
  mobileImageUrl?: string;
  link?: string;
};
export type ExtraFieldDefinition = {
  name: string;
  type: string;
  isRequired: boolean;
};
export type Company = {
  id: number;
  name: string;
  loginStrategy: 0 | 1;
  minUsersForAreaRanking: number;
  logoUrl?: string;
  loginImageUrl?: string;
  loginAnnouncementIsEnabled: boolean;
  loginAnnouncementSeverity?: string;
  mainPageAnnouncementIsEnabled: boolean;
  mainPageAnnouncementSeverity?: string;
  comingSoonModeIsEnabled: boolean;
  comingSoonModeBackgroundImageUrl?: string;
  comingSoonModeTextColor?: string;
  isologoUrl?: string;
  appCustomDomain?: string;
  appPath?: string;
  users: string[];
  invitations: string[];
  news: string[];
  areas: Area[];
  tournaments: Tournament[];
  dreamFinalsSettings: DreamFinalSettings[];
  combinedRoundsRankingDefinitions: string[];
  requiresEmailVerification: boolean;
  isReseller: boolean;
  allowUserEmailEdit: boolean;
  allowUserNameEdit: boolean;
  disableInitialTour: boolean;
  disableTrivias: boolean;
  disableNews: boolean;
  disableNewsFromHome: boolean;
  disableRankingsUserNames: boolean;
  disableRankingsTable: boolean;
  disableRankingsMainPageCards: boolean;
  disableRankingsMainPageSummary: boolean;
  disableAreaRankingsMainPageSummary: boolean;
  disableTournamentDetails: boolean;
  disableRegister: boolean;
  disableGoogleLogin: boolean;
  customFontFamily?: string;
  superiorBanner?: Banner;
  middleBanner?: Banner;
  sideBanner?: Banner;
  maxUsersAmount: number;
  theme: object;
  customColors: object;
  extraFields: ExtraFieldDefinition[];
  managerUsers?: User[];
  deletedAt?: string;
  rssFeeds?: string[];
};
export type Trivia = {
  id: number;
  title: string;
  subtitle?: string;
  startsAt: string;
  finishesAt: string;
  coverImageUrl: string;
  pointsByCorrectAnswer: number;
  randomSelectionCount: number;
  questions: TriviaQuestion[];
  company: Company;
  maxTimePerQuestion: number;
  status: 'draft' | 'published' | 'paused';
  allowReplay: boolean;
};
export type TriviaQuestion = {
  id: number;
  question: string;
  choices: TriviaQuestionChoice[];
  trivia: Trivia;
};
export type TriviaAnswer = {
  id: number;
  question: TriviaQuestion;
  chosen?: TriviaQuestionChoice;
  solution: TriviaSolution;
  elapsedTime: number;
  user: User;
  createdAt: string;
};
export type TriviaSolution = {
  id: number;
  status: 'started' | 'finished' | 'forfeited';
  date: string;
  answers: TriviaAnswer[];
  user: User;
  trivia: Trivia;
  points?: number;
  totalElapsedTime?: number;
};
export type InternalRanking = {
  id: number;
  company: Company;
  owner: User;
  name: string;
  prize: string;
  members: string[];
};
export type InternalRankingMember = {
  id: number;
  user: User;
  internalRanking: InternalRanking;
  status: 'pending' | 'accepted' | 'rejected' | 'banned';
};
export type ExtraField = {
  name: string;
  value: object;
};
export type User = {
  id: number;
  showInitialTour: boolean;
  name: string;
  email: string;
  externalId: string;
  loginProviderId: string;
  loginProviderName?: 'google';
  preferredLanguage: string;
  profilePictureUrl?: string;
  portraitPictureUrl?: string;
  recoverPasswordToken?: string;
  emailVerified: boolean;
  notificationsEnabled: boolean;
  predictions: Prediction[];
  triviaSolutions: TriviaSolution[];
  triviaAnswers: TriviaAnswer[];
  internalRankingMemberships: InternalRankingMember[];
  company: Company;
  role: 'player' | 'admin' | 'reseller' | 'manager';
  managedCompanies?: any[];
  areaLevel1: Area;
  areaLevel1Id?: number;
  areaLevel2: Area;
  areaLevel2Id?: number;
  areaLevel3: Area;
  areaLevel3Id?: number;
  deletedAt?: string;
  loginCount?: number;
  extraFields: ExtraField[];
};
export type Prediction = {
  id: number;
  homeTeamGoals: number;
  awayTeamGoals: number;
  penaltiesWinner?: 'home' | 'away';
  status: string;
  points?: number;
  match: Match;
  user: User;
};
export type Match = {
  id: number;
  date: string;
  venue: string;
  referee: string;
  homeTeamGoals?: number;
  awayTeamGoals?: number;
  homeTeamPenaltyGoals?: number;
  awayTeamPenaltyGoals?: number;
  elapsedTime?: number;
  status: string;
  homeTeam: Team;
  awayTeam: Team;
  penaltiesWinner?: 'home' | 'away';
  round: Round;
  externalId?: string;
  alreadySentOpenNotification?: boolean;
  hasPenalties?: boolean;
  isTwoWaysSecondMatch?: boolean;
  wasSuspended?: boolean;
  predictions: Prediction[];
};
export type MatchDate = {
  id: string;
  date: string;
};
export type MatchDatesList = {
  rounds: MatchDate[];
  currentRound: MatchDate;
};
export type UpdateMatchDto = {
  date?: string;
  venue?: string;
  referee?: string;
  homeTeam?: EntityDto;
  awayTeam?: EntityDto;
  hasPenalties?: boolean;
  isTwoWaysSecondMatch?: boolean;
  wasSuspended?: boolean;
  alreadySentOpenNotification?: boolean;
  homeTeamGoals?: number;
  awayTeamGoals?: number;
  status?:
    | 'FT'
    | 'AET'
    | 'PEN'
    | 'SUSP'
    | 'INT'
    | 'PST'
    | 'CANC'
    | 'ABD'
    | 'AWD'
    | 'WO'
    | '1H'
    | '2H'
    | 'ET'
    | 'HT'
    | 'BT'
    | 'P'
    | 'LIVE'
    | 'TBD'
    | 'NS';
};
export type MatchTeam = {
  id: number;
  name: string;
};
export type MatchTime = {
  elapsed: number;
  extra?: number;
};
export type MatchPlayer = {
  id: number;
  name: string;
};
export type MatchEvent = {
  type: 'Goal' | 'Card' | 'subst' | 'var';
  team: MatchTeam;
  time: MatchTime;
  player: MatchPlayer;
  detail: string;
  comments?: string;
};
export type MatchResult = {
  result: string;
  round: Round;
};
export type Rule = {
  rule: string;
  points: number;
  awarded: boolean;
};
export type Explanation = {
  rules: Rule[];
  maxPossiblePoints: number;
  matchIsNonAwardable: boolean;
  points: number;
  expectedResult: object;
  actualResult: object;
};
export type CreatePredictionDto = {
  match: EntityDto;
  homeTeamGoals: number;
  awayTeamGoals: number;
  penaltiesWinner: string;
};
export type CreateRoundDto = {};
export type OrderedRound = {
  id: number;
  name: string;
  position: number;
  tournamentId: number;
  date: string;
};
export type OrderedRoundsList = {
  rounds: OrderedRound[];
  currentRound: Round;
};
export type UpdateRoundDto = {};
export type CreateTeamDto = {
  name: string;
  logoUrl: string;
};
export type TeamsList = {
  count: number;
  data: Team[];
};
export type UpdateTeamDto = {
  name?: string;
  logoUrl?: string;
};
export type TournamentsList = {
  count: number;
  data: Tournament[];
};
export type CreateTournamentDto = {
  name: string;
  logoUrl: string;
  country: EntityDto;
  defaultRssFeeds?: string[];
  endDate?: string;
  hasPenalties?: boolean;
  hasTwoWayMatches?: boolean;
  isActive?: boolean;
  showNoMoreRoundsWarning?: boolean;
  importNewMatchesWithPenalties?: boolean;
};
export type TournamentDetails = {
  id: number;
  name: string;
  logoUrl?: string;
  isActive?: boolean;
  endDate?: string;
  hasPenalties?: boolean;
  hasTwoWayMatches: boolean;
  importNewMatchesWithPenalties: boolean;
  showNoMoreRoundsWarning: boolean;
  country: Country;
  rounds: Round[];
  season?: string;
  externalId?: string;
  defaultRssFeeds?: string[];
  currentRound: Round;
};
export type UpdateTournamentDto = {
  name?: string;
  logoUrl?: string;
  country?: EntityDto;
  defaultRssFeeds?: string[];
  endDate?: string;
  hasPenalties?: boolean;
  hasTwoWayMatches?: boolean;
  isActive?: boolean;
  showNoMoreRoundsWarning?: boolean;
  importNewMatchesWithPenalties?: boolean;
};
export type ApiSportsSeasonCoverageFixtures = {
  events: boolean;
  lineups: boolean;
  statistics_fixtures: boolean;
  statistics_players: boolean;
};
export type ApiSportsSeasonCoverage = {
  fixtures: ApiSportsSeasonCoverageFixtures;
  standings: boolean;
  players: boolean;
  top_scorers: boolean;
  top_assists: boolean;
  top_cards: boolean;
  injuries: boolean;
  predictions: boolean;
  odds: boolean;
};
export type ImportableTournament = {
  name: string;
  logoUrl?: string;
  isImported: boolean;
  externalId: string;
  season: string;
  coverage: ApiSportsSeasonCoverage;
};
export type MatchResultBody = {
  homeTeamGoals?: number;
  awayTeamGoals?: number;
  status?:
    | 'FT'
    | 'AET'
    | 'PEN'
    | 'SUSP'
    | 'INT'
    | 'PST'
    | 'CANC'
    | 'ABD'
    | 'AWD'
    | 'WO'
    | '1H'
    | '2H'
    | 'ET'
    | 'HT'
    | 'BT'
    | 'P'
    | 'LIVE'
    | 'TBD'
    | 'NS';
  wasSuspended?: boolean;
  hasPenalties?: boolean;
  isTwoWaysSecondMatch?: boolean;
};
export type TeamStanding = {
  position: number;
  points: number;
};
export type CreateUserDto = {
  name: string;
  email?: string;
  externalId?: string;
  preferredLanguage?: string;
  password: string;
  notificationsEnabled?: boolean;
  areaLevel1?: EntityDto;
  areaLevel2?: EntityDto;
  areaLevel3?: EntityDto;
  customFields?: string;
  profilePictureUrl?: string;
  portraitPictureUrl?: string;
  recoverPasswordToken?: string;
  loginProviderId?: string;
  loginProviderName?: string;
  showInitialTour?: boolean;
};
export type UpdateUserDto = {
  name?: string;
  email?: string;
  externalId?: string;
  preferredLanguage?: string;
  password?: string;
  notificationsEnabled?: boolean;
  areaLevel1?: EntityDto;
  areaLevel2?: EntityDto;
  areaLevel3?: EntityDto;
  customFields?: string;
  profilePictureUrl?: string;
  portraitPictureUrl?: string;
  recoverPasswordToken?: string;
  loginProviderId?: string;
  loginProviderName?: string;
  showInitialTour?: boolean;
};
export type UpdateAreaDto = {
  name?: string;
};
export type Ranking = {
  id: number;
  position: number;
  points: number;
  dreamFinalPoints: number;
  exactMatches: number;
  user: User;
};
export type AreaRanking = {
  areaId: number;
  areaName: string;
  points: number;
  position: number;
  userCount: number;
};
export type GlobalRanking = {
  id: number;
  position: number;
  points: number;
  dreamFinalPoints: number;
  exactMatches: number;
  user: User;
  triviaPoints: number;
};
export type TournamentRanking = {
  id: number;
  position: number;
  points: number;
  dreamFinalPoints: number;
  exactMatches: number;
  user: User;
  tournament: Tournament;
};
export type RoundRanking = {
  id: number;
  position: number;
  points: number;
  dreamFinalPoints: number;
  exactMatches: number;
  user: User;
  round: Round;
};
export type TournamentRoundRanking = {
  id: number;
  name: string;
  ranking: TournamentRanking;
  currentRound?: RoundRanking;
};
export type LatestRankingsDto = {
  global: GlobalRanking;
  tournaments: TournamentRoundRanking[];
};
export type CombinedRoundsRankingDefinition = {
  id: number;
  name: string;
  rounds: Round[];
};
export type CreateCombinedRoundsRankingDefinitionDto = {
  company?: EntityDto;
  name: string;
  rounds: string[];
};
export type UpdateCombinedRoundsRankingDefinitionDto = {
  company?: EntityDto;
  name?: string;
  rounds?: string[];
};
export type News = {
  id: number;
  title: string;
  description: string;
  language: string;
  summary?: string;
  imageUrl?: string;
  category?: string;
  link?: string;
  externalId?: string;
  date?: string;
  fixed: boolean;
  company: Company;
};
export type CreateNewsDto = {
  title: string;
  description: string;
  image?: string;
  link?: string;
  externalId?: string;
  date?: string;
  fixed?: boolean;
};
export type NewsDetails = {
  id: number;
  title: string;
  description: string;
  language: string;
  summary?: string;
  imageUrl?: string;
  category?: string;
  link?: string;
  externalId?: string;
  date?: string;
  fixed: boolean;
  company: Company;
  source?: string;
};
export type UpdateNewsDto = {
  title?: string;
  description?: string;
  image?: string;
  link?: string;
  externalId?: string;
  date?: string;
  fixed?: boolean;
};
export type NewsSourceDownload = {};
export type NewsSource = {
  id: string;
  image: string;
  link: string;
  name: string;
  country?: string;
  excludeCategories: string[];
  languages: string[];
  type: string;
};
export type CreateCompanyDto = {
  name: string;
  logoUrl?: string;
  isologoUrl?: string;
  loginImageUrl?: string;
  loginAnnouncementSeverity?: string;
  loginAnnouncementIsEnabled?: boolean;
  mainPageAnnouncementSeverity?: string;
  mainPageAnnouncementIsEnabled?: boolean;
  comingSoonModeIsEnabled?: boolean;
  comingSoonModeBackgroundImageUrl?: string;
  comingSoonModeTextColor?: string;
  disableInitialTour?: boolean;
  disableTrivias?: boolean;
  disableNews?: boolean;
  disableNewsFromHome?: boolean;
  disableRegister?: boolean;
  disableGoogleLogin?: boolean;
  disableTournamentDetails?: boolean;
  disableRankingsUserNames?: boolean;
  disableRankingsTable?: boolean;
  disableRankingsMainPageCards?: boolean;
  disableRankingsMainPageSummary?: boolean;
  disableAreaRankingsMainPageSummary?: boolean;
  appCustomDomain?: string;
  appPath?: string;
  loginStrategy?: number;
  tournaments?: EntityDto[];
  requiresEmailVerification?: boolean;
  isReseller?: boolean;
  allowUserEmailEdit?: boolean;
  allowUserNameEdit?: boolean;
  rssFeeds?: string[];
  customTheme?: string;
  customColors?: object;
  customFontFamily?: string;
  customFields?: string;
  superiorBannerMobileImageUrl?: string;
  superiorBannerLink?: string;
  middleBannerDesktopImageUrl?: string;
  middleBannerMobileImageUrl?: string;
  middleBannerLink?: string;
  sideBannerDesktopImageUrl?: string;
  sideBannerMobileImageUrl?: string;
  sideBannerLink?: string;
  managerUsers?: string[];
  maxUsersAmount?: number;
  minUsersForAreaRanking?: number;
  extraFields?: object;
};
export type CompanyWithUsersCount = {
  id: number;
  name: string;
  loginStrategy: 0 | 1;
  minUsersForAreaRanking: number;
  logoUrl?: string;
  loginImageUrl?: string;
  loginAnnouncementIsEnabled: boolean;
  loginAnnouncementSeverity?: string;
  mainPageAnnouncementIsEnabled: boolean;
  mainPageAnnouncementSeverity?: string;
  comingSoonModeIsEnabled: boolean;
  comingSoonModeBackgroundImageUrl?: string;
  comingSoonModeTextColor?: string;
  isologoUrl?: string;
  appCustomDomain?: string;
  appPath?: string;
  users: string[];
  invitations: string[];
  news: string[];
  areas: Area[];
  tournaments: Tournament[];
  dreamFinalsSettings: DreamFinalSettings[];
  combinedRoundsRankingDefinitions: string[];
  requiresEmailVerification: boolean;
  isReseller: boolean;
  allowUserEmailEdit: boolean;
  allowUserNameEdit: boolean;
  disableInitialTour: boolean;
  disableTrivias: boolean;
  disableNews: boolean;
  disableNewsFromHome: boolean;
  disableRankingsUserNames: boolean;
  disableRankingsTable: boolean;
  disableRankingsMainPageCards: boolean;
  disableRankingsMainPageSummary: boolean;
  disableAreaRankingsMainPageSummary: boolean;
  disableTournamentDetails: boolean;
  disableRegister: boolean;
  disableGoogleLogin: boolean;
  customFontFamily?: string;
  superiorBanner?: Banner;
  middleBanner?: Banner;
  sideBanner?: Banner;
  maxUsersAmount: number;
  theme: object;
  customColors: object;
  extraFields: ExtraFieldDefinition[];
  managerUsers?: User[];
  deletedAt?: string;
  rssFeeds?: string[];
  usersCount: number;
};
export type UpdateCompanyDto = {
  name?: string;
  logoUrl?: string;
  isologoUrl?: string;
  loginImageUrl?: string;
  loginAnnouncementSeverity?: string;
  loginAnnouncementIsEnabled?: boolean;
  mainPageAnnouncementSeverity?: string;
  mainPageAnnouncementIsEnabled?: boolean;
  comingSoonModeIsEnabled?: boolean;
  comingSoonModeBackgroundImageUrl?: string;
  comingSoonModeTextColor?: string;
  disableInitialTour?: boolean;
  disableTrivias?: boolean;
  disableNews?: boolean;
  disableNewsFromHome?: boolean;
  disableRegister?: boolean;
  disableGoogleLogin?: boolean;
  disableTournamentDetails?: boolean;
  disableRankingsUserNames?: boolean;
  disableRankingsTable?: boolean;
  disableRankingsMainPageCards?: boolean;
  disableRankingsMainPageSummary?: boolean;
  disableAreaRankingsMainPageSummary?: boolean;
  appCustomDomain?: string;
  appPath?: string;
  loginStrategy?: number;
  tournaments?: EntityDto[];
  requiresEmailVerification?: boolean;
  isReseller?: boolean;
  allowUserEmailEdit?: boolean;
  allowUserNameEdit?: boolean;
  rssFeeds?: string[];
  customTheme?: string;
  customColors?: object;
  customFontFamily?: string;
  customFields?: string;
  superiorBannerMobileImageUrl?: string;
  superiorBannerLink?: string;
  middleBannerDesktopImageUrl?: string;
  middleBannerMobileImageUrl?: string;
  middleBannerLink?: string;
  sideBannerDesktopImageUrl?: string;
  sideBannerMobileImageUrl?: string;
  sideBannerLink?: string;
  managerUsers?: string[];
  maxUsersAmount?: number;
  minUsersForAreaRanking?: number;
  extraFields?: object;
};
export type FileUploadResponse = {
  url: string;
  cdnUrl: string;
};
export type Invitation = {
  id: number;
  externalId: string;
  name?: string;
  email?: string;
  role: 'player' | 'admin' | 'reseller' | 'manager';
  company: Company;
  areaLevel1: Area;
  areaLevel1Id?: number;
  areaLevel2: Area;
  areaLevel2Id?: number;
  areaLevel3: Area;
  areaLevel3Id?: number;
};
export type CreateInvitationDto = {
  externalId: string;
  name?: string;
  email?: string;
  company?: EntityDto;
  areaLevel1?: EntityDto;
  areaLevel2?: EntityDto;
  areaLevel3?: EntityDto;
  role?: string;
};
export type ImportReport = {
  status: 'success' | 'delayed';
  ignoredRowsCount?: number;
  createdCount?: number;
  updatedCount?: number;
  errorCount?: number;
  newAreas?: string[];
  existingAreas?: string[];
};
export type ImportFromExcelBody = {
  url: string;
};
export type UpdateInvitationDto = {
  externalId?: string;
  name?: string;
  email?: string;
  company?: EntityDto;
  areaLevel1?: EntityDto;
  areaLevel2?: EntityDto;
  areaLevel3?: EntityDto;
  role?: string;
};
export type DeleteManyResult = {
  count: number;
};
export type CreateTranslationDto = {
  data: string;
  locale: string;
};
export type Translation = {
  id: number;
  data: string;
  locale: string;
  company: Company;
};
export type SendEmailDto = {
  from: string;
  to: string;
  subject: string;
  body: string;
  replyTo?: string;
};
export type ExternalSupportEmailDto = {};
export type ProfileSupportEmailDto = {
  name: string;
  userEmail: string;
  emailToAnswer?: string;
  inquiry: string;
};
export type CreateDreamFinalSettingsDto = {
  company?: EntityDto;
  limitRound: EntityDto;
  tournament: EntityDto;
  awardedPositions: number;
  awardedPoints: number;
};
export type TournamentAndDreamFinal = {
  tournament: Tournament;
  dreamFinalSettings?: DreamFinalSettings;
};
export type UpdateDreamFinalSettingsDto = {
  company?: EntityDto;
  limitRound?: EntityDto;
  tournament?: EntityDto;
  awardedPositions?: number;
  awardedPoints?: number;
};
export type DreamFinalPrediction = {
  id: number;
  user: User;
  tournament: Tournament;
  team1: Team;
  team2: Team;
  team3: Team;
  team4: Team;
};
export type CreateDreamFinalPredictionDto = {
  tournament: EntityDto;
  team1?: EntityDto;
  team2?: EntityDto;
  team3?: EntityDto;
  team4?: EntityDto;
};
export type TermsAndConditions = {
  id: number;
  termsAndConditions: string;
  company: Company;
};
export type CreateTermsAndConditionsDto = {
  termsAndConditions: string;
  company?: EntityDto;
};
export type GameRules = {
  id: number;
  generalRules: string;
  penaltiesRules: string;
  twoWayPenaltiesRules: string;
  dreamFinalRules: string;
};
export type RegisteredUsersPerDate = {
  date: string;
  count: number;
};
export type Stats = {
  companiesCount: number;
  totalUsersCount: number;
  registeredUsersCount: number;
  lastWeekActiveUsersCount: number;
  pendingUsersCount: number;
  todaysNewUsersCount: number;
  nextWeekMatchesCount: number;
  areasCount: number;
  recentlyRegisteredUsers: RegisteredUsersPerDate[];
};
export type GlobalSettings = {
  id: number;
  impersonationEnabled: boolean;
};
export type CreateGlobalSettingsDto = {
  impersonationEnabled: boolean;
};
export type TournamentCustomSettings = {
  id: number;
  company: Company;
  tournament: Tournament;
  round: Round;
};
export type CreateTournamentCustomSettingsDto = {
  company: EntityDto;
  tournament: EntityDto;
  round: EntityDto;
};
export type JobLog = {
  id: number;
  type:
    | 'ranking:generate'
    | 'tournament:import'
    | 'match:import-result'
    | 'domains:godaddy-create-domain-forward'
    | 'domains:godaddy-create-site-subdomains'
    | 'domains:acm-request-certificate'
    | 'domains:acm-solve-challenge'
    | 'domains:ses-create-identity'
    | 'domains:ses-verify-identity'
    | 'domains:ses-solve-challenge'
    | 'domains:cloudfront-update-distribution'
    | 'news:parsehub:fetch'
    | 'invitation:import';
  metadata?: string;
  start: string;
  end: string;
};
export type CreateTriviaQuestionChoiceDto = {
  option: string;
  isCorrect: boolean;
};
export type CreateTriviaQuestionDto = {
  question: string;
  choices: CreateTriviaQuestionChoiceDto[];
};
export type CreateTriviaDto = {
  title: string;
  subtitle?: string;
  coverImageUrl: string;
  allowReplay?: boolean;
  pointsByCorrectAnswer: number;
  randomSelectionCount: number;
  questions: CreateTriviaQuestionDto[];
  status?: 'draft' | 'published' | 'paused';
  startsAt: string;
  finishesAt: string;
  maxTimePerQuestion: number;
};
export type UpdateTriviaDto = {
  title?: string;
  subtitle?: string;
  coverImageUrl?: string;
  allowReplay?: boolean;
  pointsByCorrectAnswer?: number;
  randomSelectionCount?: number;
  questions?: CreateTriviaQuestionDto[];
  status?: 'draft' | 'published' | 'paused';
  startsAt?: string;
  finishesAt?: string;
  maxTimePerQuestion?: number;
};
export type PlayableTrivia = {
  trivia: Trivia;
  solution?: TriviaSolution;
};
export type CreateTriviaSolutionDto = {
  answers?: string[];
  date?: string;
};
export type CreateTriviaAnswerDto = {
  question: EntityDto;
  chosen?: EntityDto;
  elapsedTime: number;
};
export type TriviaRanking = {
  id: number;
  position: number;
  points: number;
  totalElapsedTime: number;
  user: User;
};
export type AreaTriviaRanking = {
  areaId: number;
  areaName: string;
  points: number;
  position: number;
  userCount: number;
};
export type Domain = {
  id: number;
  name: string;
};
export type DomainsList = {
  count: number;
  data: Domain[];
};
export type CertificateDetails = {
  certificateARN: string;
};
export type UpdateDomainDto = {
  name?: string;
};
export type CreateDomainDto = {
  name: string;
};
export type CreateInternalRankingDto = {
  owner: EntityDto;
  company?: EntityDto;
  name: string;
  prize: string;
  members: string[];
};
export type UpdateInternalRankingDto = {
  owner?: EntityDto;
  company?: EntityDto;
  name?: string;
  prize?: string;
  members?: string[];
};
export type Notification = {
  id: number;
  kind:
    | 'internal-ranking:invitation-sent'
    | 'internal-ranking:invitation-accepted';
  company: Company;
  user: User;
  payload?: string;
  status: 'read' | 'unread';
  createdAt: string;
};
export type CreateAwardDto = {
  name: string;
  imageUrl: string;
  description?: string;
  position?: number;
  customPosition?: string;
};
export type Award = {
  id: number;
  name: string;
  imageUrl: string;
  description?: string;
  position?: number;
  customPosition?: string;
  company: Company;
};
export type UserWithToken = {
  user: User;
  accessToken: string;
};
export type LoginUserDto = {
  email?: string;
  externalId?: string;
  password: string;
};
export type GoogleSignInDto = {
  accessToken: string;
};
export const {
  useCountriesControllerCreateMutation,
  useCountriesControllerFindAllQuery,
  useCountriesControllerImportExternalCountriesMutation,
  useCountriesControllerFindOneQuery,
  useCountriesControllerUpdateMutation,
  useCountriesControllerRemoveMutation,
  useMatchesControllerCreateMutation,
  useMatchesControllerFindAllQuery,
  useMatchesControllerFindAllByCompanyQuery,
  useMatchesControllerFindRecentDatesForQuery,
  useMatchesControllerFindOneQuery,
  useMatchesControllerUpdateMutation,
  useMatchesControllerRemoveMutation,
  useMatchesControllerFindOneEventsQuery,
  useMatchesControllerFindLastResultsQuery,
  usePredictionsControllerAwardPointsMutation,
  usePredictionsControllerExplainQuery,
  usePredictionsControllerFindAllQuery,
  usePredictionsControllerUpsertMutation,
  usePredictionsControllerFindOneQuery,
  useRoundsControllerCreateMutation,
  useRoundsControllerFindAllQuery,
  useRoundsControllerSummaryByDateQuery,
  useRoundsControllerFindRecentRoundsForQuery,
  useRoundsControllerFindRecentRoundsInTournamentQuery,
  useRoundsControllerFindOneQuery,
  useRoundsControllerUpdateMutation,
  useRoundsControllerRemoveMutation,
  useRoundsControllerFindMatchesQuery,
  useRoundsControllerSummaryQuery,
  useTeamsControllerCreateMutation,
  useTeamsControllerFindAllQuery,
  useTeamsControllerFindOneQuery,
  useTeamsControllerUpdateMutation,
  useTeamsControllerRemoveMutation,
  useTeamsControllerByTournamentQuery,
  useTournamentsControllerFindAllQuery,
  useTournamentsControllerCreateMutation,
  useTournamentsControllerFindAllByCompanyQuery,
  useTournamentsControllerFindTournamentWinnersQuery,
  useTournamentsControllerFindTournamentRoundsQuery,
  useTournamentsControllerGetSummaryQuery,
  useTournamentsControllerFindOneQuery,
  useTournamentsControllerUpdateMutation,
  useTournamentsControllerRemoveMutation,
  useTournamentImporterControllerReimportMutation,
  useTournamentImporterControllerImportExternalLeagueSeasonMutation,
  useTournamentImporterControllerFindAllQuery,
  useTournamentImporterControllerImportMatchResultMutation,
  useTournamentImporterControllerImportRoundResultMutation,
  useTournamentImporterControllerImportMatchResultManualMutation,
  useTournamentImporterControllerStandingsQuery,
  useUsersControllerGetProxyImageQuery,
  useUsersControllerCreateMutation,
  useUsersControllerFindAllQuery,
  useUsersControllerFindAllResellerUsersQuery,
  useUsersControllerSearchQuery,
  useUsersControllerFindMeQuery,
  useUsersControllerFindOneQuery,
  useUsersControllerUpdateMutation,
  useUsersControllerRemoveMutation,
  useUsersControllerRemoveMultiMutation,
  useUsersControllerDownloadCsvMutation,
  useAreasControllerFindAllQuery,
  useAreasControllerUpdateMutation,
  useRankingsControllerCalculateRoundRankingMutation,
  useRankingsControllerRoundRankingQuery,
  useRankingsControllerCalculateTournamentRankingMutation,
  useRankingsControllerTournamentRankingQuery,
  useRankingsControllerForceCalculateDreamFinalTournamentRankingMutation,
  useRankingsControllerTournamentRankingByAreaQuery,
  useRankingsControllerCalculateGlobalRankingMutation,
  useRankingsControllerGlobalRankingQuery,
  useRankingsControllerRoundRankingRankingByAreaQuery,
  useRankingsControllerRoundRankingMeQuery,
  useRankingsControllerLatestRankingsMeQuery,
  useRankingsControllerGlobalRankingByAreaQuery,
  useRankingsControllerCalculateCombinedRoundsRankingMutation,
  useRankingsControllerCombinedRoundsRankingQuery,
  useRankingsControllerCombinedRoundsRankingRankingByAreaQuery,
  useCombinedRoundsRankingDefinitionsControllerCreateMutation,
  useCombinedRoundsRankingDefinitionsControllerFindAllQuery,
  useCombinedRoundsRankingDefinitionsControllerFindOneQuery,
  useCombinedRoundsRankingDefinitionsControllerUpdateMutation,
  useCombinedRoundsRankingDefinitionsControllerRemoveMutation,
  useNewsControllerCreateMutation,
  useNewsControllerFindAllQuery,
  useNewsControllerCombinedNewsQuery,
  useNewsControllerFetchParseHubNewsMutation,
  useNewsControllerFindOneRssByExternalidQuery,
  useNewsControllerFindOneQuery,
  useNewsControllerUpdateMutation,
  useNewsControllerRemoveMutation,
  useNewsControllerDownloadRssFeedsQuery,
  useNewsControllerAvailableRssFeedsQuery,
  useCompaniesControllerCreateMutation,
  useCompaniesControllerFindAllQuery,
  useCompaniesControllerFindAvailableFontsQuery,
  useCompaniesControllerFindAllResellersQuery,
  useCompaniesControllerFindMeQuery,
  useCompaniesControllerUpdateMeMutation,
  useCompaniesControllerFindMeWithAreasQuery,
  useCompaniesControllerSearchQuery,
  useCompaniesControllerManifestQuery,
  useCompaniesControllerFindOneQuery,
  useCompaniesControllerUpdateMutation,
  useCompaniesControllerRemoveMutation,
  useCompaniesControllerMyTournamentsQuery,
  useCompaniesControllerTournamentsQuery,
  useFilesControllerUploadMutation,
  useFilesControllerUploadByUrlMutation,
  useInvitationsControllerCreateMutation,
  useInvitationsControllerFindAllQuery,
  useInvitationsControllerFindAllPendingManagersQuery,
  useInvitationsControllerImportFromExcelMutation,
  useInvitationsControllerDownloadSampleExcelQuery,
  useInvitationsControllerFindOneQuery,
  useInvitationsControllerUpdateMutation,
  useInvitationsControllerRemoveMutation,
  useInvitationsControllerFindOneByJwtQuery,
  useInvitationsControllerFindOneByExternalIdQuery,
  useInvitationsControllerRemoveAllMutation,
  useInvitationsControllerRemoveMultiMutation,
  useInvitationsControllerDownloadCsvMutation,
  useTranslationsControllerCreateMutation,
  useTranslationsControllerCreateCommonMutation,
  useTranslationsControllerAllCommonLocalesQuery,
  useTranslationsControllerAllCompanyLocalesQuery,
  useTranslationsControllerGetCompanyTranslationsQuery,
  useTranslationsControllerGetCommonTranslationsQuery,
  useTranslationsControllerRemoveMutation,
  useEmailsControllerSendMutation,
  useEmailsControllerSendExternalSupportMutation,
  useEmailsControllerSendProfileSupportMutation,
  useDreamFinalSettingsControllerCreateMutation,
  useDreamFinalSettingsControllerMeQuery,
  useDreamFinalSettingsControllerSettingsByTournamentQuery,
  useDreamFinalSettingsControllerFindOneQuery,
  useDreamFinalSettingsControllerUpdateMutation,
  useDreamFinalSettingsControllerRemoveMutation,
  useDreamFinalPredictionControllerUpsertMutation,
  useDreamFinalPredictionControllerPredictionByTournamentQuery,
  useDreamFinalPredictionControllerFindOneQuery,
  useDreamFinalPredictionControllerRemoveMutation,
  useGameRulesControllerTermsAndConditionsQuery,
  useGameRulesControllerUpsertTermsAndConditionsMutation,
  useGameRulesControllerTournamentRulesQuery,
  useStatsControllerStatsQuery,
  useGlobalSettingsControllerUpsertMutation,
  useGlobalSettingsControllerFindAllQuery,
  useTournamentCustomSettingsControllerFindAllByCompanyQuery,
  useTournamentCustomSettingsControllerFindAllByMeQuery,
  useTournamentCustomSettingsControllerFindByTournamentQuery,
  useTournamentCustomSettingsControllerCreateMutation,
  useTournamentCustomSettingsControllerRemoveMutation,
  useJobLogsControllerFindAllQuery,
  useJobLogsControllerFindOneQuery,
  useTriviasControllerCreateTemplateMutation,
  useTriviasControllerGetTriviaTemplatesQuery,
  useTriviasControllerCreateMutation,
  useTriviasControllerGetCompanyTriviasQuery,
  useTriviasControllerUpdateMutation,
  useTriviasControllerGetTriviaQuery,
  useTriviasControllerRemoveMutation,
  useTriviaSolutionsControllerGetSolutionQuery,
  useTriviaSolutionsControllerStartSolvingTriviaMutation,
  useTriviaSolutionsControllerResetTriviaMutation,
  useTriviaSolutionsControllerFofeitTriviaSolutionMutation,
  useTriviaSolutionsControllerAnswerTriviaQuestionMutation,
  useTriviaSolutionsControllerDeleteTriviaSolutionMutation,
  useTriviaRankingsControllerCalculateSingleTriviaRankingMutation,
  useTriviaRankingsControllerSingleTriviaRankingQuery,
  useTriviaRankingsControllerSingleTriviaRankingByAreaQuery,
  useTriviaRankingsControllerCalculateGlobalTriviaRankingMutation,
  useTriviaRankingsControllerGlobalTriviaRankingQuery,
  useTriviaRankingsControllerGlobalTriviaRankingByAreaQuery,
  useDomainsControllerCreateMutation,
  useDomainsControllerFindAllQuery,
  useDomainsControllerAcmCertificatesQuery,
  useDomainsControllerAcmCurrentCertificateQuery,
  useDomainsControllerAcmCertificateChallengesQuery,
  useDomainsControllerAcmSolveCertificateChallengesMutation,
  useDomainsControllerSesIdentitiesQuery,
  useDomainsControllerSesMissingIdentitiesQuery,
  useDomainsControllerCreateMissingSesIdentitiesMutation,
  useDomainsControllerCloudfrontDistributionsQuery,
  useDomainsControllerSyncCloudfrontDistributionsMutation,
  useDomainsControllerSyncCloudfrontDistributionByIdMutation,
  useDomainsControllerMissingDomainsQuery,
  useDomainsControllerConfigureMissingDomainsMutation,
  useDomainsControllerFindOneQuery,
  useDomainsControllerUpdateMutation,
  useDomainsControllerRemoveMutation,
  useDomainsControllerMigrateToCloudFlareMutation,
  useDomainsControllerCloudFlareStatusQuery,
  useInternalRankingsControllerCreateMutation,
  useInternalRankingsControllerSettingsByTournamentQuery,
  useInternalRankingsControllerMeQuery,
  useInternalRankingsControllerFindOneQuery,
  useInternalRankingsControllerUpdateMutation,
  useInternalRankingsControllerRemoveMutation,
  useInternalRankingsControllerAcceptInternalRankingMemberMutation,
  useInternalRankingsControllerRejecInternalRankingMemberMutation,
  useInternalRankingsControllerRemoveInternalRankingMemberMutation,
  useInternalRankingsControllerAddInternalRankingMemberMutation,
  useNotificationsControllerFindAllQuery,
  useNotificationsControllerFindOneMutation,
  useAwardsControllerUpsertMutation,
  useAwardsControllerFindAllQuery,
  useAwardsControllerRemoveMutation,
  useAwardsControllerFindOneQuery,
  useAuthControllerRegisterMutation,
  useAuthControllerLoginMutation,
  useAuthControllerGoogleLoginMutation,
  useAuthControllerVerifyEmailMutation,
  useAuthControllerAcceptInvitationMutation,
  useAuthControllerAcceptInvitationByJwtMutation,
  useAuthControllerSendVerificationEmailMutation,
  useAuthControllerInitRecoverPasswordMutation,
  useAuthControllerFinishRecoverPasswordMutation,
} = injectedRtkApi;
