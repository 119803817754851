import {
  Box,
  Button,
  Grid,
  Skeleton,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { any } from 'ramda';
import { useAppDispatch, useAppSelector } from './app/hooks';
import {
  Tournament,
  useCompaniesControllerFindMeQuery,
  useMatchesControllerFindRecentDatesForQuery,
  useRoundsControllerFindRecentRoundsForQuery,
  useTournamentsControllerGetSummaryQuery,
} from './app/services/futbolProdeApi';
import RoundsCarousel from './features/calendar/RoundsCarousel';
import GlobalRankingTableSummary from './features/rankings/GlobalRankingTableSummary';
import TournamentSummary from './features/summary/TournamentSummary';
import UserPositionsSummary from './features/rankings/UserPositionsSummary';
import {
  setCurrentRound,
  setCurrentDate,
  restartVisualization,
  selectDisableRestartButton,
  setActiveVisualization,
} from './features/summary/summaryNavigationSlice';
import { DreamFinalAccordion } from './features/dreamFinal/DreamFinalAccordion';
import { useMemo } from 'react';
import { Banner } from './features/summary/Banner';
import DatesCarousel from './features/calendar/DatesCarousel';
import { ActiveButton, OverlappingButtons } from './common/OverlappingButtons';
import { currentTimezone } from './common/dateTimeUtils';
import { Case, Default, Switch, Unless, When } from 'react-if';
import TournamentSummaryByDate from './features/summary/TournamentSummaryByDate';
import { useTranslation } from 'react-i18next';
import GlobalAreaRankingTableSummary from './features/rankings/GlobalAreaRankingTableSummary';

export default function Home() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data: company } = useCompaniesControllerFindMeQuery();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const { data: tournamentsSummary, isLoading } =
    useTournamentsControllerGetSummaryQuery();

  const { activeVisualization, round: currentRound } = useAppSelector(
    (state) => state.summaryNavigation,
  );

  const activeVisualizationButton = useMemo(
    () => (activeVisualization === 'round' ? 'left' : 'right'),
    [activeVisualization],
  );

  const setActiveVisualizationButton = (value: ActiveButton) => {
    const visualization = value === 'left' ? 'round' : 'date';
    dispatch(setActiveVisualization(visualization));
  };

  const { data: roundsAndCurrentRound, isLoading: areRoundsLoading } =
    useRoundsControllerFindRecentRoundsForQuery(undefined, {
      skip: activeVisualization === 'date',
    });

  const { data: datesAndCurrentDates, isLoading: areDatesLoading } =
    useMatchesControllerFindRecentDatesForQuery(currentTimezone(), {
      skip: activeVisualization === 'round',
    });

  const disableRestartVisualizationButton = useAppSelector(
    selectDisableRestartButton,
  );

  const dreamFinalAccordion = useMemo(
    () => (
      <Box id="dream-final-panel">
        {tournamentsSummary?.data.map((tournament: Tournament) => (
          <DreamFinalAccordion tournament={tournament} key={tournament.name} />
        ))}
      </Box>
    ),
    [tournamentsSummary],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Banner
          banner={company?.middleBanner}
          style={{ objectFit: 'cover', width: '100%' }}
        />
      </Grid>
      <Unless condition={company?.disableRankingsMainPageCards}>
        <UserPositionsSummary />
      </Unless>
      <When condition={isSmallScreen}>
        <Grid item xs={12} md={0}>
          {dreamFinalAccordion}
        </Grid>
      </When>
      <Grid item container xs={12} rowGap={1} justifyContent="space-between">
        <OverlappingButtons
          id="summary-navigation"
          activeButton={activeVisualizationButton}
          setActiveButton={setActiveVisualizationButton}
          leftText={t('homePage.summary.byRound')}
          rightText={t('homePage.summary.byDay')}
        />
        <Button
          variant="outlined"
          onClick={() => dispatch(restartVisualization())}
          disabled={disableRestartVisualizationButton}
        >
          {t('round.goToCurrentRound')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {activeVisualization === 'date' ? (
          <DatesCarousel
            recentRounds={datesAndCurrentDates?.rounds}
            isLoading={areDatesLoading}
            onRoundClick={(round) => dispatch(setCurrentDate(round))}
          />
        ) : (
          <RoundsCarousel
            recentRounds={roundsAndCurrentRound?.rounds}
            isLoading={areRoundsLoading}
            defaultTournamentId={tournamentsSummary?.data?.[0]?.id}
            summary={tournamentsSummary?.data}
            onRoundClick={(round) => dispatch(setCurrentRound(round))}
            currentRound={currentRound}
          />
        )}
      </Grid>
      <Grid item xs={12} lg={9}>
        <Switch>
          <Case condition={isLoading}>
            <Skeleton width="100%" />
          </Case>
          <Case condition={activeVisualization === 'date'}>
            {tournamentsSummary?.data.map((tournament) => (
              <TournamentSummaryByDate
                tournament={tournament}
                key={tournament.id}
              />
            ))}
          </Case>
          <Default>
            {tournamentsSummary?.data
              .filter((tournament) =>
                any((it) => it.id === currentRound.id, tournament.rounds),
              )
              .map((tournament) => (
                <TournamentSummary
                  tournament={tournament}
                  key={tournament.id}
                />
              ))}
          </Default>
        </Switch>
      </Grid>
      <Unless condition={isSmallScreen}>
        <Grid item md={3}>
          <Stack direction="column" spacing={2}>
            {dreamFinalAccordion}
            <Unless
              condition={
                company?.disableRankingsTable ||
                company?.disableRankingsMainPageSummary
              }
            >
              <GlobalRankingTableSummary />
            </Unless>
            <Unless
              condition={
                company?.disableRankingsTable ||
                company?.disableAreaRankingsMainPageSummary
              }
            >
              <GlobalAreaRankingTableSummary />
            </Unless>
            <Banner
              banner={company?.sideBanner}
              style={{
                objectFit: 'cover',
                width: '100%',
                marginTop: '20px',
              }}
            />
          </Stack>
        </Grid>
      </Unless>
      <Grid item xs={12} md={3} sx={{ display: { xs: 'initial', lg: 'none' } }}>
        <Banner
          banner={company?.sideBanner}
          style={{
            objectFit: 'cover',
            width: '100%',
            marginTop: '20px',
          }}
        />
      </Grid>
    </Grid>
  );
}
