import {
  Navigate,
  createBrowserRouter,
  useParams,
  useLocation,
} from 'react-router-dom';
import { useAuth } from '@futbolprode/ui-common';
import EmptyLayout from './layouts/EmptyLayout';
import { isNil } from 'ramda';
import AdmissionContainer from './features/auth/AdmissionContainer';
import LoginForm from './features/auth/login/LoginForm';
import HomeLayout from './layouts/HomeLayout';
import NewsLayout from './layouts/NewsLayout';
import FullPageNewsItemView from './features/news/page/FullPageNewsItemView';
import { VerifyEmailPage } from './features/auth/signup/VerifyEmailPage';
import ActivateAccountForm from './features/auth/signup/ActivateAccountForm';
import RankingsLayout from './layouts/RankingsLayout';
import AreaRankingsLayout from './layouts/AreaRankingsLayout';
import { GameRulesDashboard } from './features/gameRules/GameRulesLayout';
import { TermsAndConditionsLayout } from './features/gameRules/TermsAndConditionsLayout';
import ProfileLayout from './layouts/ProfileLayout';
import { useCompaniesControllerFindMeQuery } from './app/services/futbolProdeApi';
import { ExternalSupportForm } from './features/auth/signup/ExternalSupportForm';
import FinishRecoverForm from './features/auth/signup/FinishRecoverForm';
import InitRecoverForm from './features/auth/signup/InitRecoverForm';
import AppLayout from './layouts/AppLayout';
import TriviasHomePage from './features/trivia/TriviasHomePage';
import SignUpPage from './features/auth/signup/SignUpPage';
import TriviaPlayPage from './features/trivia/play/TriviaPlayPage';
import { juegoAppConfig } from './juegoAppConfig';
import { UnhandledErrorPage } from './ErrorPages';
import AwardsPage from './features/awards/AwardsPage';

const rootRoute = '/:company?';
const appRoute = `${rootRoute}/app`;
const gamerulesRoute = `${rootRoute}/app/gamerules`;
const termsAndConditionsRoute = `${rootRoute}/app/termsandconditions`;
const profileRoute = `${rootRoute}/app/profile`;

const RootRoutes = {
  LOGIN: `login`,
  SIGN_UP: `register`,
  ACTIVATE: `activate`,
  INIT_RECOVER: `init-recover`,
  FINISH_RECOVER: `finish-recover`,
  VERIFY: `verify`,
  SUPPORT: `support`,
};

const AppRoutes = {
  AWARDS: 'awards',
  TOURNAMENTS: `tournaments`,
  NEWS: 'news',
  TRIVIAS: 'trivia',
  RANKINGS: 'positions',
  AREA_RANKINGS: 'positions/areas',
  GAME_RULES: gamerulesRoute,
  TERMS_AND_CONDITIONS: termsAndConditionsRoute,
  PROFILE: profileRoute,
  PROFILE_SUPPORT: `${profileRoute}/support`,
};

const isRootRoute = (path: string) => Object.values(RootRoutes).includes(path);

export const Routes = {
  ROOT: rootRoute,
  ...RootRoutes,
  APP: appRoute,
  ...AppRoutes,
};

export function useCompanyAdminUrls() {
  const { data } = useCompaniesControllerFindMeQuery();
  const { rootRoute } = useCompanyRoutes();
  return {
    adminRootRoute: (path: string) =>
      `https://${juegoAppConfig.adminSubdomain}.${
        data?.appCustomDomain ?? juegoAppConfig.defaultDomain
      }${rootRoute(path)}`,
  };
}

export function useCompanyRoutes() {
  const { company } = useParams();
  const companyPrefix =
    !isNil(company) && !isRootRoute(company) ? `/${company}` : '';

  return {
    appRoute(path = '') {
      return `${companyPrefix}/app/${path}`;
    },

    rootRoute(path = '') {
      return `${companyPrefix}/${path}`;
    },
  };
}

function CheckLoginState() {
  const user = useAuth();
  const { appRoute, rootRoute } = useCompanyRoutes();
  const initialRoute = isNil(user.user) ? rootRoute(Routes.LOGIN) : appRoute();
  return <Navigate to={initialRoute} />;
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const user = useAuth();
  const { rootRoute } = useCompanyRoutes();
  const location = useLocation();
  return user.user ? (
    children
  ) : (
    <Navigate to={rootRoute(Routes.LOGIN)} state={{ from: location }} replace />
  );
}

const router = createBrowserRouter([
  {
    path: Routes.ROOT,
    errorElement: <UnhandledErrorPage />,
    element: <EmptyLayout />,
    children: [
      { index: true, element: <CheckLoginState /> },
      {
        path: Routes.LOGIN,
        element: (
          <AdmissionContainer
            titleKey="login.signin"
            Form={LoginForm}
          ></AdmissionContainer>
        ),
      },
      {
        path: Routes.SIGN_UP,
        element: <SignUpPage />,
      },
      {
        path: Routes.ACTIVATE,
        element: (
          <AdmissionContainer
            titleKey="signup.activateYourAccount"
            Form={ActivateAccountForm}
          ></AdmissionContainer>
        ),
      },
      {
        path: Routes.INIT_RECOVER,
        element: (
          <AdmissionContainer
            titleKey="activation.recoverPassword"
            Form={InitRecoverForm}
          ></AdmissionContainer>
        ),
      },
      {
        path: Routes.FINISH_RECOVER,
        element: (
          <AdmissionContainer
            titleKey="activation.modifyPassword"
            Form={FinishRecoverForm}
          ></AdmissionContainer>
        ),
      },

      {
        path: Routes.VERIFY,
        element: (
          <AdmissionContainer Form={VerifyEmailPage}></AdmissionContainer>
        ),
      },
      {
        path: Routes.SUPPORT,
        element: (
          <AdmissionContainer
            titleKey="support.word"
            Form={ExternalSupportForm}
          ></AdmissionContainer>
        ),
      },
    ],
  },
  {
    path: Routes.APP,
    errorElement: <UnhandledErrorPage />,
    element: (
      <RequireAuth>
        <AppLayout />
      </RequireAuth>
    ),
    children: [
      { index: true, element: <HomeLayout /> },
      { path: Routes.AWARDS, element: <AwardsPage /> },
      { path: Routes.NEWS, element: <NewsLayout /> },
      { path: `${Routes.NEWS}/:newsId`, element: <FullPageNewsItemView /> },
      { path: `${Routes.NEWS}/rss`, element: <FullPageNewsItemView /> },
      { path: Routes.TRIVIAS, element: <TriviasHomePage /> },
      { path: `${Routes.TRIVIAS}/:triviaId`, element: <TriviaPlayPage /> },
      { path: Routes.RANKINGS, element: <RankingsLayout /> },
      { path: Routes.AREA_RANKINGS, element: <AreaRankingsLayout /> },
    ],
  },
  {
    path: Routes.GAME_RULES,
    errorElement: <UnhandledErrorPage />,
    element: (
      <RequireAuth>
        <GameRulesDashboard />
      </RequireAuth>
    ),
  },
  {
    path: Routes.TERMS_AND_CONDITIONS,
    errorElement: <UnhandledErrorPage />,
    element: (
      <RequireAuth>
        <TermsAndConditionsLayout />
      </RequireAuth>
    ),
  },
  {
    path: Routes.PROFILE,
    errorElement: <UnhandledErrorPage />,
    element: (
      <RequireAuth>
        <ProfileLayout />
      </RequireAuth>
    ),
  },
  {
    path: Routes.PROFILE_SUPPORT,
    errorElement: <UnhandledErrorPage />,
    element: (
      <RequireAuth>
        <ProfileLayout tabIndex={1} />
      </RequireAuth>
    ),
  },
]);

export default router;
