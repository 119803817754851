import { Alert, Button, Stack } from '@mui/material';
import { RankingTable } from '../features/rankings/page/RankingTable';
import { useAppSelector } from '../app/hooks';
import { selectActiveRanking } from '../features/rankings/activeRankingSlice';
import {
  CombinedRoundsRankingDefinition,
  Ranking,
  Tournament,
  useAreasControllerFindAllQuery,
  useCombinedRoundsRankingDefinitionsControllerFindAllQuery,
  useCompaniesControllerFindMeWithAreasQuery,
  useRankingsControllerCombinedRoundsRankingRankingByAreaQuery,
  useRankingsControllerGlobalRankingByAreaQuery,
  useRankingsControllerRoundRankingRankingByAreaQuery,
  useRankingsControllerTournamentRankingByAreaQuery,
  useRoundsControllerFindRecentRoundsInTournamentQuery,
  useTournamentsControllerFindAllByCompanyQuery,
} from '../app/services/futbolProdeApi';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AreaRanking } from '../app/services/futbolProdeApi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { GridValueGetterParams } from '@mui/x-data-grid';
import { isEmpty, isNil, last } from 'ramda';
import { useCompanyRoutes } from '../router';
import { Nullable } from 'typescript-nullable';
import SplitLayout from './SplitLayout';
import RankingsTabs from '../features/rankings/page/RankingsTabs';
import { useDebounce } from 'use-debounce';
import { When } from 'react-if';

function useActiveRankingQuery({
  recentCombinedRounds,
  isShowingGeneralRanking,
  isShowingRounds,
  tournaments,
  selectedTournament,
  search,
}: {
  recentCombinedRounds: CombinedRoundsRankingDefinition[];
  isShowingGeneralRanking: boolean;
  isShowingRounds: boolean;
  tournaments?: Tournament[];
  selectedTournament?: Tournament;
  search?: string;
}) {
  const useCombinedRounds =
    !isNil(recentCombinedRounds) && !isEmpty(recentCombinedRounds);

  const { roundId, skip, take } = useAppSelector(selectActiveRanking);

  const [areaParams] = useSearchParams();
  const maybeAreaLevel = areaParams.get('areaLevel');
  const maybeParentAreaId = areaParams.get('parentAreaId');
  const currentAreaLevel = useMemo(
    () => (maybeAreaLevel ? parseInt(maybeAreaLevel, 10) : undefined),
    [maybeAreaLevel],
  );
  const parentAreaId = useMemo(
    () => (maybeParentAreaId ? parseInt(maybeParentAreaId, 10) : undefined),
    [maybeParentAreaId],
  );

  const commonParams = {
    search,
    skip,
    take,
    areaLevel: currentAreaLevel,
    parentAreaId,
  };

  const { data: globalRanking, isFetching: isFetchingGlobal } =
    useRankingsControllerGlobalRankingByAreaQuery(commonParams, {
      skip: !currentAreaLevel,
    });

  const { data: tournamentRanking, isFetching: isFetchingTournament } =
    useRankingsControllerTournamentRankingByAreaQuery(
      currentAreaLevel &&
        !isShowingGeneralRanking &&
        tournaments &&
        selectedTournament
        ? { ...commonParams, id: selectedTournament.id }
        : skipToken,
    );

  const { data: roundRanking, isFetching: isFetchingRound } =
    useRankingsControllerRoundRankingRankingByAreaQuery(
      !isShowingGeneralRanking && roundId && !useCombinedRounds
        ? { ...commonParams, id: roundId }
        : skipToken,
    );

  const { data: combinedRoundRanking, isFetching: isFetchingCombinedRound } =
    useRankingsControllerCombinedRoundsRankingRankingByAreaQuery(
      !isShowingGeneralRanking && roundId && useCombinedRounds
        ? { ...commonParams, id: roundId }
        : skipToken,
    );

  const activeRankingWithPagination = isShowingGeneralRanking
    ? globalRanking
    : isShowingRounds
    ? useCombinedRounds
      ? combinedRoundRanking
      : roundRanking
    : tournamentRanking;

  return {
    data: activeRankingWithPagination,
    isFetching: [
      isFetchingGlobal,
      isFetchingTournament,
      isFetchingRound,
      isFetchingCombinedRound,
    ].some((it) => it),
  };
}

function AreaRankingsLayout() {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const navigate = useNavigate();
  const { appRoute } = useCompanyRoutes();
  const { t } = useTranslation();
  const { tabIndex, mode } = useAppSelector(selectActiveRanking);
  const [areaParams, setAreaParams] = useSearchParams();
  const maybeAreaNames = areaParams.get('areaNames');
  const maybeAreaLevel = areaParams.get('areaLevel');
  const currentAreaLevel = useMemo(
    () => (maybeAreaLevel ? parseInt(maybeAreaLevel, 10) : undefined),
    [maybeAreaLevel],
  );
  const areaRankingNames = useMemo(
    () => (maybeAreaNames ?? '').split(','),
    [maybeAreaNames],
  );

  const isShowingGeneralRanking = tabIndex === 0;
  const isShowingRounds = mode === 'rounds';
  const { data: tournaments } = useTournamentsControllerFindAllByCompanyQuery();

  const selectedTournament = isShowingGeneralRanking
    ? undefined
    : tournaments?.[tabIndex - 1];

  const { data: recentAndCurrentRounds, isLoading: recentRoundsAreLoading } =
    useRoundsControllerFindRecentRoundsInTournamentQuery(
      isShowingGeneralRanking || !selectedTournament
        ? skipToken
        : selectedTournament.id.toString(),
    );

  const recentRounds = Nullable.map((it) => it.rounds, recentAndCurrentRounds);
  const currentRound = Nullable.map(
    (it) => it.currentRound,
    recentAndCurrentRounds,
  );

  const currentRankingName = t('ranking.positions.positionsIn', {
    place:
      currentAreaLevel === 1
        ? t(`user.areaLevel1`, { ns: 'company' })
        : areaRankingNames.reverse().join(', '),
  });

  const {
    data: combinedRoundsDefinitions,
    isLoading: combinedDefinitionsLoading,
  } = useCombinedRoundsRankingDefinitionsControllerFindAllQuery(
    recentRounds ? undefined : skipToken,
  );
  const recentRoundIds = recentRounds?.map((r) => r.id) ?? [];
  const recentCombinedRounds = (combinedRoundsDefinitions ?? []).filter((it) =>
    it.rounds
      .map((r) => r.id)
      .some((roundId) => recentRoundIds.includes(roundId)),
  );

  const currentCombinedRound =
    recentCombinedRounds.find((it) =>
      it.rounds.some((r) => r.id === currentRound?.id),
    ) ?? last(recentCombinedRounds);

  const activeCurrentRound = currentCombinedRound ?? currentRound;

  const activeRecentRounds = isEmpty(recentCombinedRounds)
    ? recentRounds
    : recentCombinedRounds;

  const { data: activeRankingWithPagination, isFetching: isRankingFetching } =
    useActiveRankingQuery({
      isShowingRounds,
      isShowingGeneralRanking,
      recentCombinedRounds,
      tournaments,
      selectedTournament,
      search: debouncedSearchTerm,
    });

  const activeRanking: Ranking[] | AreaRanking[] | undefined =
    activeRankingWithPagination?.data;
  const activeCount: number | undefined =
    activeRankingWithPagination?.pagination?.count;
  const activePage: number | undefined =
    activeRankingWithPagination?.pagination?.page;

  const { data: company } = useCompaniesControllerFindMeWithAreasQuery();
  const { data: subareas } = useAreasControllerFindAllQuery(
    isNil(currentAreaLevel) ? skipToken : currentAreaLevel + 1,
  );

  const hasChildrenArea =
    (currentAreaLevel ?? 3) < 3 && (subareas?.length ?? 0) > 0;
  const areaRankingColumns: any = useMemo(
    () => [
      {
        field: 'areaName',
        headerName: t(`user.areaLevel${currentAreaLevel ?? 1}`, {
          ns: 'company',
        }),
        description: t(`user.areaLevel${currentAreaLevel ?? 1}`, {
          ns: 'company',
        }),
        minWidth: 200,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
      },
      {
        field: 'points',
        headerName: t('ranking.positions.average'),
        description: t('ranking.positions.average'),
        minWidth: 200,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'exactMatches',
        headerName: t('ranking.positions.exactMatches'),
        description: t('ranking.positions.exactMatches'),
        minWidth: 100,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
      },
      ...(hasChildrenArea
        ? [
            {
              field: 'area2Count',
              headerName: t(`user.areaLevel${(currentAreaLevel ?? 1) + 1}`, {
                ns: 'company',
              }),
              description: t(`user.areaLevel${(currentAreaLevel ?? 1) + 1}`, {
                ns: 'company',
              }),
              minWidth: 200,
              flex: 1,
              align: 'center',
              headerAlign: 'center',
              valueGetter: (params: GridValueGetterParams) =>
                (subareas ?? []).filter(
                  (it: any) =>
                    (it.parentArea || { id: -100 }).id === params.row.areaId,
                ).length,
            },
          ]
        : []),
      {
        field: 'userCount',
        headerName: t('ranking.positions.users'),
        description: t('ranking.positions.users'),
        minWidth: 200,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
      },
      ...(hasChildrenArea
        ? [
            {
              field: 'childRankingLinkButton',
              headerName: t('ranking.positions.seeRankingByChild', {
                childAreaName: t(
                  `user.areaLevel${(currentAreaLevel ?? 1) + 1}`,
                  {
                    ns: 'company',
                  },
                ),
              }),
              description: t('ranking.positions.seeRankingByChild', {
                childAreaName: t(
                  `user.areaLevel${(currentAreaLevel ?? 1) + 1}`,
                  {
                    ns: 'company',
                  },
                ),
              }),
              minWidth: 200,
              flex: 1,
              align: 'center',
              headerAlign: 'center',
              renderCell: (params: GridValueGetterParams) => (
                <Button
                  onClick={() => {
                    setAreaParams((prev) => ({
                      areaLevel: ((currentAreaLevel ?? 1) + 1).toString(),
                      parentAreaId: params.row.areaId,
                      areaNames: prev.get('areaNames')
                        ? prev
                            .get('areaNames')
                            ?.concat(`,${params.row.areaName}`)
                        : params.row.areaName,
                    }));
                  }}
                  size="small"
                  sx={{ textTransform: 'none' }}
                  variant="outlined"
                >
                  Ver{' '}
                  {t(`user.areaLevel${(currentAreaLevel ?? 1) + 1}`, {
                    ns: 'company',
                  })}
                </Button>
              ),
            },
          ]
        : []),
      {
        field: 'usersRankingLinkButton',
        headerName: t('ranking.positions.seeRankingByUsers'),
        description: t('ranking.positions.seeRankingByUsers'),
        minWidth: 200,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridValueGetterParams) => (
          <Button
            onClick={() =>
              navigate(
                appRoute(
                  `positions?areaId=${params.row.areaId}&areaLevel=${currentAreaLevel}`,
                ),
              )
            }
            size="small"
            sx={{ textTransform: 'none' }}
            variant="outlined"
          >
            {t('ranking.positions.seeUsers')}
          </Button>
        ),
      },
    ],
    [
      subareas,
      currentAreaLevel,
      t,
      hasChildrenArea,
      setAreaParams,
      navigate,
      appRoute,
    ],
  );

  return (
    <SplitLayout
      title={currentRankingName}
      logoUrl={
        isShowingGeneralRanking
          ? company?.isologoUrl
          : selectedTournament?.logoUrl
      }
      HeaderContent={<RankingsTabs tournaments={tournaments ?? []} />}
      MainContent={
        <Stack spacing={2}>
          <When condition={(company?.minUsersForAreaRanking ?? 0) > 1}>
            <Alert severity="warning">
              {t('ranking.positions.minUsersForAreaAlert', {
                min: company?.minUsersForAreaRanking,
                areaName: t(`user.areaLevel1`, { ns: 'company' }),
              })}
            </Alert>
          </When>
          <RankingTable
            isGlobalRanking={isShowingGeneralRanking}
            selectedTournament={selectedTournament}
            recentRounds={activeRecentRounds as any}
            currentRound={activeCurrentRound}
            recentRoundsAreLoading={
              recentRoundsAreLoading || combinedDefinitionsLoading
            }
            columns={areaRankingColumns}
            activeRanking={activeRanking}
            activeCount={activeCount}
            activePage={activePage}
            isRankingLoading={isRankingFetching}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            currentAreaLevel={currentAreaLevel}
          />
        </Stack>
      }
    />
  );
}

export default AreaRankingsLayout;
