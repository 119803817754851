import { Outlet } from 'react-router-dom';
import WithAppBar from '../common/WithAppBar';

export default function AppLayout() {
  return (
    <WithAppBar>
      <Outlet />
    </WithAppBar>
  );
}
